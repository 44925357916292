import * as tslib_1 from "tslib";
import { OnInit, ElementRef, NgZone } from '@angular/core';
import { Events, IonContent, LoadingController, ModalController, AlertController, Platform, IonRouterOutlet, ToastController } from '@ionic/angular';
import { UserService } from '../services/user/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentModalPage } from './payment-modal/payment-modal.page';
import { ChatService } from '../services/chat.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Storage } from '@ionic/storage';
import { ImageModalPage } from '../image-modal/image-modal.page';
import { ConfigService } from '../services/config/config.service';
import { OrderPaymentPage } from '../order-payment/order-payment.page';
import { RateOrderPage } from '../rating/rate-order/rate-order.page';
export class ChatBotPage {
    constructor(events, userService, loadingController, router, modalController, ngZone, alertController, platform, chatService, toastController, keyboard, route, storage, configService) {
        this.events = events;
        this.userService = userService;
        this.loadingController = loadingController;
        this.router = router;
        this.modalController = modalController;
        this.ngZone = ngZone;
        this.alertController = alertController;
        this.platform = platform;
        this.chatService = chatService;
        this.toastController = toastController;
        this.keyboard = keyboard;
        this.route = route;
        this.storage = storage;
        this.configService = configService;
        this.userMsgTxt = '';
        this.msg = {
            type: null,
            message: null,
            createdAt: null,
            images: null,
            isRead: null,
            author: null,
            published: null,
            mob: null,
            thumb: null,
            imageCount: null
        };
        this.allMsgs = [];
        this.chatLoader = false;
        this.enableScroll = true;
        this.showNoMsgs = false;
        this.showMsgLoader = false;
        this.showLoader = true;
        this.lastTimeBackPress = 0;
        this.timePeriodToExit = 2000;
        this.imgUrls = [];
        this.useToolbar = true;
        this.disableSendBtn = true;
        this.showSearch = false;
        this.setFirstImage = false;
        this.unsavedImages = {};
        window.addEventListener('keyboardWillShow', () => {
            // // console.log("Keyboard will Show");
            setTimeout(() => {
                this.ngZone.run(() => {
                    if (this.content.scrollToBottom) {
                        this.content.scrollToBottom(0);
                    }
                });
            }, 0);
        });
        this.storage.get('unsavedImages').then((val) => {
            if (val) {
                this.unsavedImages = val;
                //// console.log('val of unsavedImages', this.unsavedImages);
            }
        });
    }
    ionViewWillEnter() {
        if (this.platform.is('android')) {
            this.useToolbar = false;
        }
        this.devWidth = this.platform.width();
        this.devHeight = this.platform.height();
        //// console.log('devWidth', this.devWidth);
        //// console.log('devHeight', this.devHeight);
        if (this.devWidth <= 500) {
            this.useThumb = true;
        }
        else if (this.devWidth > 500) {
            this.useThumb = false;
        }
        if (this.devWidth < 700) {
            this.maxMessageWidth = this.devWidth - 70;
        }
        else if (this.devWidth >= 700 && this.devWidth <= 1000) {
            this.maxMessageWidth = this.devWidth - 90;
        }
        else {
            this.maxMessageWidth = this.devWidth - 100;
        }
        if (this.devWidth < 600) {
            this.imgGridWidth = this.devWidth - 106;
        }
        else if (this.devWidth >= 600 && this.devWidth <= 700) {
            this.imgGridWidth = this.devWidth - 124;
        }
        else if (this.devWidth >= 700 && this.devWidth <= 1000) {
            this.imgGridWidth = this.devWidth - 168;
        }
        else {
            this.imgGridWidth = this.devWidth - 224;
        }
    }
    preventFocusChange(e) {
        e.preventDefault();
    }
    ionViewDidEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
        });
    }
    ionViewWillLeave() {
        this.events.publish('chat:removeGetMsgsSubscription');
    }
    ngOnInit() {
        this.orderIdPrefix = this.configService.environment.orderIdPrefix;
        //// console.log('in ngOnInit');
        this.storage.get('uid').then((val) => {
            //// console.log('user id in chat-bot', val);
            this.uid = val;
            this.events.publish('chat:getMsgs', this.uid, 'user');
            this.events.publish('user:getUserDetails');
        });
        this.initializeSubscriptions();
    }
    ngOnDestroy() {
        // // console.log('in ngOnDestroyed');
        this.removeSubscriptions();
    }
    scrollToBottomOnInit() {
        setTimeout(() => {
            if (this.content.scrollToBottom) {
                this.content.scrollToBottom(0);
            }
        }, 500);
    }
    logScrolling($event) {
        if ($event.detail.scrollTop === 0 && !this.searchMsg) {
            //// console.log('in scroll top zero', $event.detail.scrollTop);
            this.chatLoader = true;
            this.showNoMsgs = false;
            this.events.publish('chat:getMoreMsgs', this.uid);
        }
    }
    scrollToFirstMessage(elementId) {
        let y = document.getElementById(elementId).offsetTop;
        //// console.log('y position', y);
        this.content.scrollToPoint(0, y);
    }
    initializeSubscriptions() {
        this.events.subscribe('chat:publishMsgs', (msgs) => {
            this.allMsgs = msgs;
            // if(this.newOrderStatus === true) {
            //   this.loader.dismiss();
            // }
            //// console.log('publish user msgs', msgs);
            this.showMsgLoader = false;
            this.showLoader = false;
            this.scrollToBottomOnInit();
            if (this.allMsgs) {
                this.makeImageUrls();
            }
            this.removeSavedImagesFromStorage();
            setTimeout(() => {
                this.enableScroll = true;
            }, 2000);
        });
        this.events.subscribe('chat:publishMoreMsgs', (msgs) => {
            // for (const msg of msgs) {
            //   this.chatLoader = false;
            //   this.allMsgs.unshift(msg.payload.doc.data());
            // }
            this.allMsgs = msgs;
            this.userMsgTxt = '';
            //// console.log('more msgs', this.allMsgs);
            this.chatLoader = false;
            this.scrollToFirstMessage('chatMessage' + 1);
            this.makeImageUrls();
        });
        this.events.subscribe('chat:noMoreMsgs', () => {
            this.chatLoader = false;
            this.showNoMsgs = true;
        });
        this.events.subscribe('user:publishUserDetails', (user) => {
            this.userData = user;
        });
        this.events.subscribe('media:chatImageSuccess', () => {
            // this.loader.dismiss();
        });
        this.events.subscribe('user:cancelOrderSuccess', () => {
            this.loader.dismiss();
        });
        this.events.subscribe('media:showUnsavedImages', (msgId, imageResponse) => {
            this.unsavedImages[msgId] = imageResponse;
            this.storage.set('unsavedImages', this.unsavedImages);
            //// console.log('unsavedImages', this.unsavedImages);
        });
        this.events.subscribe('user:deleteProductSuccesss', () => {
            this.presentAlert('Product Deleted Successfully!');
            this.loading.dismiss();
        });
    }
    removeSavedImagesFromStorage() {
        let arrayOfUnsavedImagesIds = [];
        arrayOfUnsavedImagesIds = Object.keys(this.unsavedImages);
        for (let i = 0; i < this.allMsgs.length; i++) {
            for (let j = 0; j < arrayOfUnsavedImagesIds.length; j++) {
                if (this.allMsgs[i].id === arrayOfUnsavedImagesIds[j] && this.allMsgs[i].msgData.published === true) {
                    this.unsavedImages[arrayOfUnsavedImagesIds[j]] = null;
                    this.storage.set('unsavedImages', this.unsavedImages);
                }
            }
        }
    }
    makeImageUrls() {
        //// console.log('in makeImageUrls');
        if (this.allMsgs.length) {
            for (let i = 0; i < this.allMsgs.length; i++) {
                if (this.allMsgs[i].msgData.images && (this.allMsgs[i].msgData.type === "image" || this.allMsgs[i].msgData.type === "broadcast")) {
                    for (let y = 0; y < this.allMsgs[i].msgData.images.length; y++) {
                        this.imgUrls.push(this.allMsgs[i].msgData.images[y]);
                    }
                }
                else if (this.allMsgs[i].msgData.type === "order" && this.allMsgs[i].msgData.img) {
                    this.imgUrls.push(this.allMsgs[i].msgData.img);
                }
                else {
                    continue;
                }
            }
        }
        //// console.log('imgUrls', this.imgUrls);
    }
    resize() {
        this.myInput.nativeElement.style.height = this.myInput.nativeElement.scrollHeight + 'px';
    }
    sendMessage() {
        if (this.msg.message.length !== 0) {
            this.content.scrollToBottom(0);
            this.myInput.nativeElement.style.height = 40 + 'px';
            this.enableScroll = false;
            this.showMsgLoader = true;
            this.msg.type = 'txt';
            this.msg.createdAt = new Date();
            this.msg.isRead = false;
            this.msg.author = 'user';
            this.msg.published = false;
            this.allMsgs.push({ msgData: this.msg });
            this.events.publish('chat:sendMsg', this.msg, this.uid);
            this.userMsgTxt = '';
        }
    }
    uploadImage(files) {
        const imageResponse = [];
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            reader.readAsDataURL(files.item(i));
            reader.onload = (event) => {
                let base64Image = event.target.result;
                const base64Str = base64Image.split(',');
                const size = this.calculateImageSize(base64Str[1]);
                imageResponse.push({ url: base64Image, size: size });
                //// console.log('size of image', size);
                this.msg.type = 'image';
                this.msg.createdAt = new Date();
                this.msg.isRead = false;
                this.msg.author = 'user';
                this.msg.published = false;
                this.allMsgs.push(this.msg);
                this.events.publish('media:addChatImage', this.uid, this.msg, imageResponse);
            };
        }
    }
    calculateImageSize(base64String) {
        let padding, inBytes, base64StringLength;
        if (base64String.endsWith('==')) {
            padding = 2;
        }
        else if (base64String.endsWith('=')) {
            padding = 1;
        }
        else {
            padding = 0;
        }
        base64StringLength = base64String.length;
        inBytes = (base64StringLength / 4) * 3 - padding;
        const kbytes = inBytes / 1000;
        return kbytes;
    }
    imageZoom(img) {
        //// console.log("img", img);
        let imgIndex = this.imgUrls.indexOf(img);
        this.modalController.create({
            component: ImageModalPage,
            cssClass: 'photo-modal-class',
            componentProps: {
                imgs: this.imgUrls,
                index: imgIndex
            }
        }).then(modal => modal.present());
    }
    gridImageZoom(imgs) {
        this.modalController.create({
            component: ImageModalPage,
            cssClass: 'photo-modal-class',
            componentProps: {
                imgs: imgs,
                index: 0
            }
        }).then(modal => modal.present());
    }
    singleImageZoom(img) {
        this.modalController.create({
            component: ImageModalPage,
            componentProps: {
                imgs: [{ url: img }],
                index: 0
            }
        }).then(modal => modal.present());
    }
    calcTotalAmount(products) {
        let totalAmount = 0;
        for (let i = 0; i < products.length; i++) {
            totalAmount += products[i].price;
        }
        return totalAmount;
    }
    showDeleteProduct(msgTime) {
        // let lastOrderCheckTime = moment().subtract(environment.multipleOrdersTimeLimit,'minutes');
        // if(moment(msgTime.toDate()).isSameOrAfter(lastOrderCheckTime)) {
        //   return true;
        // } else{
        //    return false;
        // }
    }
    openPaymentModal() {
        this.modalController.create({
            component: PaymentModalPage,
            cssClass: 'my-custom-modal'
        })
            .then(modalEl => {
            modalEl.present();
        });
    }
    isDate(date) {
        return date instanceof Date;
    }
    cancelOrder(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loader = yield this.loadingController.create({
                message: 'Please Wait...',
            });
            yield this.loader.present();
            this.events.publish('user:cancelOrder', orderId);
        });
    }
    cancelOrderConfirm(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: 'Are you sure you want to cancel this order?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'dark',
                        handler: (blah) => {
                            // // console.log('Confirm Cancel');
                        }
                    }, {
                        text: 'Okay',
                        handler: () => {
                            //// console.log('Confirm Okay');
                            this.cancelOrder(orderId);
                        }
                    }
                ]
            });
            yield alert.present();
        });
    }
    calculateMsgTime(time) {
        let hours = '0';
        let minutes = '0';
        if (time.toDate().getHours().toString().length > 1) {
            hours = time.toDate().getHours();
        }
        else {
            hours = '0' + time.toDate().getHours();
        }
        if (time.toDate().getMinutes().toString().length > 1) {
            minutes = time.toDate().getMinutes();
        }
        else {
            minutes = '0' + time.toDate().getMinutes();
        }
        return hours + ':' + minutes;
    }
    clearSearchMsg() {
        this.searchMsg = null;
        this.content.scrollToBottom(0);
    }
    changeInMsgInput() {
        this.msg.message = this.userMsgTxt;
        // // console.log('user msg txet', this.msg.message);
        this.disableSendBtn = false;
    }
    goToProfile() {
        this.router.navigate(['profile']);
    }
    onClickSearchBtn() {
        //// console.log('in onClickSearchBtn');
        this.showSearch = !this.showSearch;
    }
    onClickViewOrder(orderId) {
        this.router.navigate([`user-order-details/${orderId}`]);
        this.dismissChatModal();
    }
    onClickDoPayment(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: OrderPaymentPage,
                cssClass: 'custom-modal',
                componentProps: {
                    orderId: orderId,
                    userId: this.uid
                }
            });
            yield modal.present();
        });
    }
    onClickDeleteProduct(orderId, msgId, productId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: 'Please Wait...',
                duration: 10000
            });
            yield this.loading.present();
            this.events.publish('user:deletePrdouctFromChatAndOrders', orderId, msgId, productId);
        });
    }
    messageModifications(msg) {
        msg = msg.trim();
        const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        const text1 = msg.replace(exp, '<a href=\'$1\'>$1</a>');
        const exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        const finalText = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
        return finalText;
    }
    onClickDeleteProductConfirm(orderId, msgId, productId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: 'Are you sure you want to delete this product?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'dark',
                        handler: (blah) => {
                            // // console.log('Confirm Cancel');
                        }
                    }, {
                        text: 'Okay',
                        handler: () => {
                            //// console.log('Confirm Okay');
                            this.onClickDeleteProduct(orderId, msgId, productId);
                        }
                    }
                ]
            });
            yield alert.present();
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loader = yield this.loadingController.create({
                message: 'Please Wait...',
            });
            yield this.loader.present();
        });
    }
    presentToast(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                color: 'medium',
                message: msg,
                duration: 2000,
                showCloseButton: true,
                cssClass: 'toast',
                animated: true
            });
            toast.present();
        });
    }
    presentAlert(desc) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: desc,
                buttons: ['Ok']
            });
            yield alert.present();
        });
    }
    dismissChatModal() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }
    msgBtnClick(task, msgData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (task === 'orderReview') {
                const modal = yield this.modalController.create({
                    component: RateOrderPage,
                    cssClass: 'custom-modal',
                    componentProps: { orderId: msgData.orderDocId }
                });
                yield modal.present();
            }
            if (task === 'orderView') {
                this.router.navigate([`user-order-details/${msgData.orderId}`]);
                this.dismissChatModal();
            }
            if (task === 'productView') {
                this.router.navigate([`product-details/${msgData.productName}/${msgData.productId}`]);
            }
            if (task === 'cartView') {
                this.router.navigate(['user-cart']);
            }
            if (task === 'wishlistView') {
                this.router.navigate(['user-wishlist']);
            }
        });
    }
    removeSubscriptions() {
        this.events.unsubscribe('chat:publishMsgs');
        this.events.unsubscribe('chat:publishMoreMsgs');
        this.events.unsubscribe('user:publishUserDetails');
        this.events.unsubscribe('media:chatImageSuccess');
        this.events.unsubscribe('media:showUnsavedImages');
        this.events.unsubscribe('user:cancelOrderSuccess');
        this.events.unsubscribe('user:deleteProductSuccesss');
        this.events.unsubscribe('chat:noMoreMsgs');
    }
}
