<ion-header no-border>
  <ion-toolbar>
    <ion-title>{{'PLACE_MODAL.where_want_delivery' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <div class="logo">
    <img src="../../../assets/img/shop-logo-center.png">
  </div>

  <form class="form-horizontal m-t-36" #SearchPlacesForm="ngForm">
    <div class="form-group">
      <label for="txtSearchPlaces">Search for area, street name...</label>
      <div>
        <input type="text" class="form-control form-input" id="txtSearchPlaces" name="txtSearchPlaces" #txtSearchPlaces="ngModel"
        [(ngModel)]="shippingAddress" placeholder="Enter your delivery location">
      </div>
    </div>
  </form>
</ion-content>

<ion-footer no-border>

  <ion-button (click)="saveLocation()" class="btn-2" color="success" expand="full" [disabled]="disableApply()">
    {{'PLACE_MODAL.save_btn' | translate}}
  </ion-button>

</ion-footer>