import * as tslib_1 from "tslib";
import { SharedService } from './../shared/shared.service';
import { UserService } from './../user/user.service';
import { ConfigService } from './../config/config.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events, Platform } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import * as firebase from 'firebase';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
import * as i4 from "../config/config.service";
import * as i5 from "../user/user.service";
import * as i6 from "../shared/shared.service";
export class ProductSubscriptionsService {
    constructor(events, afs, logglyService, configService, userService, platform, sharedService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
        this.configService = configService;
        this.userService = userService;
        this.platform = platform;
        this.sharedService = sharedService;
        this.subscriptionCollectionRef = this.afs.collection('subscriptions');
    }
    initializeSubscriptions() {
        this.events.subscribe('product-subscriptions:saveSettings', (data) => {
            this.saveSettings(data);
        });
        this.events.subscribe('product-subscriptions:getSettings', () => {
            this.getSettings();
        });
        this.events.subscribe('product-subscriptions:payWithCash', (subData) => {
            this.payWithCash(subData);
        });
        this.events.subscribe('product-subscriptions:payWithRazorpay', (subData, mode, razorpayId) => {
            this.payWithRazorpay(subData, mode, razorpayId);
        });
        this.events.subscribe('product-subscriptions:payWithPaytm', (subData) => {
            this.payWithPaytm(subData);
        });
        this.events.subscribe('product-subscriptions:getSubscriptionsOfUser', () => {
            this.getSubscriptionsOfUser();
        });
        this.events.subscribe('product-subscriptions:toggleActive', (sid, status, index) => {
            this.toggleActive(sid, status, index);
        });
        this.events.subscribe('product-subscriptions:removeSub', (sid, index) => {
            this.removeSub(sid, index);
        });
        this.events.subscribe('product-subscriptions:getSubscriptions', () => {
            this.getSubscriptions();
        });
    }
    saveSettings(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('settings').doc('subscription').set(data);
                this.events.publish('product-subscriptions:saveSettingsSuccess');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'product-subscriptions-service:saveSettings';
                this.logglyService.log(error);
            }
        });
    }
    getSettings(returnType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settings = yield this.afs.collection('settings').doc('subscription').valueChanges().pipe(first()).toPromise();
                if (returnType == 'return') {
                    return settings;
                }
                else {
                    this.events.publish('product-subscriptions:publishSettings', settings);
                }
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'product-subscriptions-service:getSettings';
                this.logglyService.log(error);
            }
        });
    }
    payWithCash(subData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log('subData in payWithCash', subData);
            try {
                subData['createdAt'] = new Date();
                subData['payment'] = {
                    mode: 'cash'
                };
                subData['user'] = {
                    id: yield this.sharedService.getStorageUid(),
                    name: this.userService.getUserName()
                };
                yield this.subscriptionCollectionRef.add(subData);
                this.events.publish('product-subscriptions:subscriptionCreatedSuccess');
            }
            catch (error) {
                console.dir(error);
                this.events.publish('product-subscriptions:subscriptionFailed');
                error['location'] = 'product-subscriptions-service:payWithCash';
                this.logglyService.log(error);
            }
        });
    }
    payWithRazorpay(subData, mode, razorpayId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const paymentMode = 'razorpay';
                const userId = yield this.sharedService.getStorageUid();
                subData['createdAt'] = new Date();
                subData['payment'] = {
                    completed: false,
                    mode: paymentMode,
                    details: null,
                    status: 'pending'
                };
                subData['user'] = {
                    id: userId,
                    name: this.userService.getUserName()
                };
                const subDoc = yield this.subscriptionCollectionRef.add(subData);
                const subDocId = subDoc.id;
                const options = {
                    description: this.configService.environment.razorpay.description,
                    currency: this.configService.environment.razorpay.currency,
                    key: razorpayId,
                    amount: subData.amountPayable * 100,
                    name: this.configService.environment.storeName ? this.configService.environment.storeName : '',
                    image: this.configService.environment.razorpay.image,
                    prefill: {
                        method: mode,
                        contact: this.userService.getPhoneNo(),
                        name: this.userService.getUserName(),
                        email: this.userService.getUserEmail() || 'xyz@gmail.com'
                    },
                    theme: this.configService.environment.razorpay.theme,
                    payment_capture: 1
                };
                const successCallback = ((payment_id) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    const successPayment = {
                        completed: true,
                        mode: paymentMode,
                        details: { paymentId: payment_id },
                        status: 'completed'
                    };
                    yield this.subscriptionCollectionRef.doc(subDocId).update({ payment: successPayment });
                    const paymnetHistory = {
                        paidAt: new Date(),
                        subscriptionId: subDocId,
                        userId: userId,
                        mode: paymentMode,
                        details: { paymentId: payment_id },
                        type: 'subscription',
                        status: 'pending',
                        amount: subData.amountPayable
                    };
                    yield this.addPaymentHistory(paymnetHistory);
                    this.events.publish('product-subscriptions:subscriptionCreatedSuccess');
                }));
                const cancelCallback = (error) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    yield this.paymentFailedUpdate(subDocId);
                    if (error.code !== 0) {
                        this.events.publish('product-subscriptions:subscriptionFailed');
                    }
                });
                this.platform.resume.subscribe((event) => {
                    RazorpayCheckout.on('payment.success', successCallback);
                    RazorpayCheckout.on('payment.cancel', cancelCallback);
                    RazorpayCheckout.onResume(event);
                });
                RazorpayCheckout.on('payment.success', successCallback);
                RazorpayCheckout.on('payment.cancel', cancelCallback);
                RazorpayCheckout.open(options);
            }
            catch (error) {
                console.dir(error);
                this.events.publish('product-subscriptions:subscriptionFailed');
                error['location'] = 'product-subscriptions-service:payWithRazorpay';
                this.logglyService.log(error);
            }
        });
    }
    payWithPaytm(subData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const mobileNo = this.userService.getPhoneNo() ? this.userService.getPhoneNo().slice(3) : '';
                const paymentMode = 'paytm';
                const userId = yield this.sharedService.getStorageUid();
                subData['createdAt'] = new Date();
                subData['payment'] = {
                    completed: false,
                    mode: paymentMode,
                    details: null,
                    status: 'pending'
                };
                subData['user'] = {
                    id: userId,
                    name: this.userService.getUserName()
                };
                const subDoc = yield this.subscriptionCollectionRef.add(subData);
                const subDocId = subDoc.id;
                const getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
                getCheckSum({
                    orderId: subDocId.toString(),
                    customerId: userId,
                    phoneNo: mobileNo,
                    txnAmount: subData.amountPayable.toString(),
                }).then((result) => {
                    // console.log('checksum:', result.data.checksum);
                    const paytmParams = {
                        MID: result.data.mid,
                        ORDER_ID: subDocId.toString(),
                        CUST_ID: userId,
                        CHANNEL_ID: this.configService.environment.paytm.CHANNEL_ID,
                        TXN_AMOUNT: subData.amountPayable.toString(),
                        WEBSITE: this.configService.environment.paytm.WEBSITE,
                        CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + subDocId.toString(),
                        INDUSTRY_TYPE_ID: this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                        MOBILE_NO: mobileNo,
                        CHECKSUMHASH: result.data.checksum,
                        ENVIRONMENT: this.configService.environment.paytm.ENVIRONMENT
                    };
                    const successCallback = (response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (response.STATUS === 'TXN_SUCCESS') {
                            const successPayment = {
                                completed: true,
                                mode: paymentMode,
                                details: response,
                                status: 'completed'
                            };
                            yield this.subscriptionCollectionRef.doc(subDocId).update({ payment: successPayment });
                            const paymnetHistory = {
                                paidAt: new Date(),
                                subscriptionId: subDocId,
                                userId: userId,
                                mode: paymentMode,
                                details: response,
                                type: 'subscription',
                                status: 'successful',
                                amount: subData.amountPayable
                            };
                            yield this.addPaymentHistory(paymnetHistory);
                            this.events.publish('product-subscriptions:subscriptionCreatedSuccess');
                        }
                        else {
                            yield this.paymentFailedUpdate(subDocId);
                            this.events.publish('product-subscriptions:subscriptionFailed');
                        }
                    });
                    const failureCallback = (error) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        yield this.paymentFailedUpdate(subDocId);
                        this.events.publish('product-subscriptions:subscriptionFailed');
                    });
                    paytm.startPayment(paytmParams, successCallback, failureCallback);
                }).catch(function (error) {
                    const code = error.code;
                    const message = error.message;
                    const details = error.details;
                    // console.log('Error', code, message, details);
                    this.events.publish('product-subscriptions:subscriptionFailed');
                    error['location'] = 'product-subscriptions-service:payWithPaytm_1';
                    this.logglyService.log(error);
                });
            }
            catch (error) {
                console.dir(error);
                this.events.publish('product-subscriptions:subscriptionFailed');
                error['location'] = 'product-subscriptions-service:payWithPaytm_2';
                this.logglyService.log(error);
            }
        });
    }
    addPaymentHistory(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('payment').doc('history').collection('payments').add(data);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'product-subscriptions-service:addPaymentHistory';
                this.logglyService.log(error);
            }
        });
    }
    paymentFailedUpdate(subId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.subscriptionCollectionRef.doc(subId).update({
                    payment: {
                        completed: false,
                        mode: null,
                        details: null,
                        status: 'failed'
                    }
                });
            }
            catch (error) {
                error['location'] = 'product-subscriptions-service:paymentFailedUpdate';
                this.logglyService.log(error);
            }
        });
    }
    getSubscriptionsOfUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const uid = yield this.sharedService.getStorageUid();
                if (uid) {
                    const subsRef = this.afs.collection('subscriptions', ref => ref
                        .where('userId', '==', uid)
                        .orderBy('createdAt', 'desc'));
                    const subs = yield subsRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return Object.assign({ id }, data);
                    }))).pipe(first()).toPromise();
                    this.events.publish('product-subscriptions:publishSubscriptionsOfUser', subs);
                }
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'product-subscriptions-service:getSubscriptionsOfUser';
                this.logglyService.log(error);
            }
        });
    }
    toggleActive(sid, status, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('subscriptions').doc(sid).update({ active: status });
                this.events.publish('product-subscriptions:toggleActiveSuccess', index);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'product-subscriptions-service:toggleActive';
                this.logglyService.log(error);
            }
        });
    }
    removeSub(sid, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('subscriptions').doc(sid).delete();
                this.events.publish('product-subscriptions:removeSubSuccess', index);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'product-subscriptions-service:removeSub';
                this.logglyService.log(error);
            }
        });
    }
    getSubscriptions() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const subsRef = this.afs.collection('subscriptions', ref => ref
                    .orderBy('createdAt', 'desc'));
                const subs = yield subsRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                this.events.publish('product-subscriptions:publishSubscriptions', subs);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'product-subscriptions-service:getSubscriptions';
                this.logglyService.log(error);
            }
        });
    }
}
ProductSubscriptionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductSubscriptionsService_Factory() { return new ProductSubscriptionsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService), i0.ɵɵinject(i4.ConfigService), i0.ɵɵinject(i5.UserService), i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i6.SharedService)); }, token: ProductSubscriptionsService, providedIn: "root" });
