import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events, AlertController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase';
import { CartService } from '../cart/cart.service';
import { ConfigService } from '../config/config.service';
import { SharedService } from '../shared/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/fire/auth";
import * as i3 from "@ionic/angular";
import * as i4 from "../user/user.service";
import * as i5 from "@ionic-native/firebase-authentication/ngx/index";
import * as i6 from "@angular/router";
import * as i7 from "@ionic/storage";
import * as i8 from "../cart/cart.service";
import * as i9 from "../config/config.service";
import * as i10 from "../shared/shared.service";
export class AuthService {
    constructor(afs, fireAuth, events, userService, firebaseAuthentication, router, ngZone, storage, cartService, configService, alertController, sharedService) {
        this.afs = afs;
        this.fireAuth = fireAuth;
        this.events = events;
        this.userService = userService;
        this.firebaseAuthentication = firebaseAuthentication;
        this.router = router;
        this.ngZone = ngZone;
        this.storage = storage;
        this.cartService = cartService;
        this.configService = configService;
        this.alertController = alertController;
        this.sharedService = sharedService;
        this.routeFromLoginPage = false;
        this.loggedOut = false;
    }
    initializeSubscriptions() {
        this.events.subscribe('auth:login', (phoneNo) => {
            this.login(phoneNo);
        });
        this.events.subscribe('auth:sendotp', (otp) => {
            this.sendotp(otp);
        });
        this.events.subscribe('auth:checkUserStatus', (phoneNo) => {
            this.checkUserStatus(phoneNo);
        });
        this.fireAuth.authState.subscribe((user) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.cartService.setCartProducts();
            if (user) {
                this.storage.set('uid', user.uid);
                this.userService.addUserId(user.uid);
                this.userService.addPhoneNo(user.phoneNumber);
                const usersData = yield this.afs.doc(`users/${user.uid}`).valueChanges().pipe(first()).toPromise();
                if (usersData) {
                    this.storage.set('userName', usersData.name);
                    this.storage.set('userPic', usersData.dP);
                    this.storage.set('userRole', usersData.role);
                    this.storage.set('userDefaultAddress', usersData.defaultAddress);
                    if (this.configService.environment.priceReqFeature) {
                        if (usersData.hasOwnProperty('showPrices')) {
                            this.storage.set('showPrices', usersData.showPrices);
                        }
                        else {
                            this.storage.set('showPrices', false);
                        }
                    }
                    if (this.configService.environment.appAccessOnApproval) {
                        this.storage.set('accessByAdmin', 'accessByAdmin' in usersData ? usersData.accessByAdmin : false);
                    }
                    if (usersData.hasOwnProperty('subRole')) {
                        this.storage.set('userSubRole', usersData.subRole);
                    }
                    this.userService.addUserName(usersData.name);
                    if (usersData.email) {
                        this.userService.addUserEmail(usersData.email);
                    }
                    this.saveMetaData(user.uid);
                }
                this.events.publish('wishlist:getWishlist');
                this.cartService.setCartProducts();
            }
            else {
                if (this.configService.environment.priceReqFeature) {
                    this.storage.set('showPrices', false);
                }
            }
            setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (this.configService.environment.appAccessOnApproval) {
                    yield this.sharedService.checkForAppAccesss();
                }
            }), 2000);
            this.events.publish('admin-settings:getStoreInfo');
            this.events.publish('auth:hideSplashScreen');
        }));
        this.events.subscribe('auth:logout', () => {
            this.loggedOut = true;
            this.logout();
        });
        this.events.subscribe('auth:fbLogin', (phoneNo) => {
            this.fbLogin(phoneNo);
        });
        this.events.subscribe('auth:googleLogin', (phoneNo) => {
            this.googleLogin(phoneNo);
        });
    }
    saveMetaData(uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.afs.doc(`users/${uid}`).update({ lastAccessAt: new Date() });
            const analytics = {
                lastAccessAt: new Date(),
            };
            this.afs.collection('users').doc(uid).collection('analytics').doc('access').collection('data').doc('web').set(analytics);
        });
    }
    checkUserStatus(phoneNo) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const checkUserExistenceByPhone = firebase.functions().httpsCallable('users-checkUserExistenceByPhone');
                checkUserExistenceByPhone(phoneNo).then((res) => {
                    const resData = res.data;
                    if (resData.isExist) {
                        this.events.publish('auth:phoneAlreadyInUse', resData.mode);
                    }
                    else {
                        this.events.publish('auth:newUser');
                    }
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    fbLogin(phoneNo) {
        const provider = new firebase.auth.FacebookAuthProvider();
        this.socialSignin(provider, phoneNo, 'facebook');
    }
    googleLogin(phoneNo) {
        const provider = new firebase.auth.GoogleAuthProvider();
        this.socialSignin(provider, phoneNo, 'google');
    }
    socialSignin(provider, phoneNo, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            firebase.auth().signInWithPopup(provider).then((result) => {
                const token = result.credential.accessToken;
                const user = result.user;
                this.userService.socialSignInUserCheck(user.uid, user.displayName, user.email, phoneNo, type);
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage);
                const email = error.email;
                const credential = error.credential;
            });
        });
    }
    login(phoneNo) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.firebaseAuthentication.verifyPhoneNumber(phoneNo, 0).then((verificationId) => {
                this.verificationId = verificationId;
                this.events.publish('auth:gotVerificationId');
                this.userService.addPhoneNo(phoneNo);
                this.routeFromLoginPage = true;
            }).catch((err) => {
                console.log(err);
                if (err = "We have blocked all requests from this device due to unusual activity. Try again later.") {
                    this.events.publish('auth:blockUserForTooManyOTP');
                }
            });
        });
    }
    sendotp(otp) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var credential = yield firebase.auth.PhoneAuthProvider.credential(this.verificationId, otp);
            firebase.auth().signInWithCredential(credential).then((res) => {
                this.userService.checkUserAlreadyExistsOrNot(res.user.uid);
            }).catch((err) => {
                if (err === "The user account has been disabled by an administrator.") {
                    this.events.publish('auth:incorrectOTP', 'Admin has blocked you!');
                }
                else {
                    this.events.publish('auth:incorrectOTP', 'OTP did not match');
                }
            });
        });
    }
    logout() {
        try {
            this.storage.get('storeInfo').then((storeInfo) => {
                this.storage.get('checkPincode').then((isPincodeChecked) => {
                    this.storage.get('deliverySettings').then(deliverySettings => {
                        this.storage.clear();
                        this.storage.set('storeInfo', storeInfo);
                        this.storage.set('checkPincode', isPincodeChecked);
                        this.storage.set('deliverySettings', deliverySettings);
                        this.ngZone.run(() => {
                            this.router.navigate(['']);
                            ;
                        });
                    });
                });
            });
            this.fireAuth.auth.signOut();
            this.firebaseAuthentication.signOut();
            this.userService.addUserId('');
            this.userService.addPhoneNo('');
            this.cartService.cartProducts = [];
            this.cartService.cartProductsUpdated.next([]);
            this.events.publish('auth:logoutSuccess');
            this.logoutAlert();
        }
        catch (err) {
            console.dir(err);
        }
    }
    logoutAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: 'You logout successfully',
                buttons: ['OK']
            });
            yield alert.present();
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.AngularFireAuth), i0.ɵɵinject(i3.Events), i0.ɵɵinject(i4.UserService), i0.ɵɵinject(i5.FirebaseAuthentication), i0.ɵɵinject(i6.Router), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i7.Storage), i0.ɵɵinject(i8.CartService), i0.ɵɵinject(i9.ConfigService), i0.ɵɵinject(i3.AlertController), i0.ɵɵinject(i10.SharedService)); }, token: AuthService, providedIn: "root" });
