import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UserService } from './user/user.service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "./user/user.service";
export class ChatService {
    constructor(events, afs, userService) {
        this.events = events;
        this.afs = afs;
        this.userService = userService;
        this.allMsgs = [];
        this.lastMsgsData = [];
    }
    initializeSubscriptions() {
        this.events.subscribe('chat:sendMsg', (msg, uid) => {
            this.sendMsg(msg, uid);
        });
        this.events.subscribe('chat:getMsgs', (id, role) => {
            this.getMsgs(id, role);
        });
        this.events.subscribe('chat:getMoreMsgs', (id) => {
            this.getMoreMsgs(id);
        });
        this.events.subscribe('chat:getLastMsgs', () => {
            this.getLastMsgs();
        });
        this.events.subscribe('chat:loadMoreMessagesForAdminHome', () => {
            this.loadMoreMessagesForAdminHome();
        });
        this.events.subscribe('chat:getPaymentDetails', () => {
            this.getPaymentDetails();
        });
        this.events.subscribe('chat:removeLastMsgSubscription', () => {
            if (this.lastMsgsSub) {
                //// console.log('removeLastMsgSucscription...');
                this.lastMsgsSub.unsubscribe();
            }
        });
        this.events.subscribe('chat:removeGetMsgsSubscription', () => {
            if (this.getMsgsSub) {
                //// console.log('removeGetMsgsSubscription...');
                this.getMsgsSub.unsubscribe();
            }
        });
        this.events.subscribe('chat:getUnreadMsgOfAdmin', (uid) => {
            this.getUnreadMsgOfAdmin(uid);
        });
        this.chatRef = this.afs.collection('chats');
    }
    sendMsg(msg, uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //// console.log('msg in chat service,', msg);
            const lastMsgData = yield this.chatRef.doc(uid).valueChanges().pipe(first()).toPromise();
            msg.published = true;
            if (msg.type === 'txt') {
                this.chatRef.doc(uid).update({ lastMessage: msg.message, lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
            }
            else if (msg.type === 'order' && msg.status === 'Rejected') {
                this.chatRef.doc(uid).update({ lastMessage: 'Your order is Rejected.',
                    lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
            }
            else if (msg.type === 'order' && msg.status === 'Cancelled') {
                this.chatRef.doc(uid).update({ lastMessage: 'Your order is cancelled.',
                    lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
            }
            else if (msg.type === 'order' && msg.status === 'Confirmed') {
                this.chatRef.doc(uid).update({ lastMessage: 'Your order is confirmed.',
                    lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
            }
            else if (msg.type === 'order' && msg.status === 'Dispatched') {
                this.chatRef.doc(uid).update({ lastMessage: 'Your order is dispatched.',
                    lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
            }
            else if (msg.type === 'order' && msg.status === 'Delivered') {
                this.chatRef.doc(uid).update({ lastMessage: 'Your order is delivered.',
                    lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
            }
            else if (msg.type === 'order' && msg.status === 'Returned') {
                this.chatRef.doc(uid).update({ lastMessage: 'Your order is returned.',
                    lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
            }
            else if (msg.type === 'order' && msg.status === 'PaymentMsg') {
                this.chatRef.doc(uid).update({ lastMessage: 'Payment is successful.',
                    lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
            }
            else if (msg.type === 'order' && msg.status === 'PaymentRequest') {
                this.chatRef.doc(uid).update({ lastMessage: 'Please do the payment of your order.',
                    lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
            }
            else if (msg.type === 'order' && msg.status === 'deliveryStarted') {
                this.chatRef.doc(uid).update({ lastMessage: 'Delivery has started of this order.',
                    lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
            }
            if (msg.author === 'user') {
                if (lastMsgData.adminActive === false) {
                    yield this.chatRef.doc(uid).collection('messages').add(msg);
                    this.chatRef.doc(uid).update({ unreadMsgs: lastMsgData.unreadMsgs + 1 });
                }
                else {
                    msg.isRead = true;
                    yield this.chatRef.doc(uid).collection('messages').add(msg);
                }
            }
            else {
                if (lastMsgData.userActive === false) {
                    yield this.chatRef.doc(uid).collection('messages').add(msg);
                    this.chatRef.doc(uid).update({ unreadAdminMsgs: lastMsgData.unreadAdminMsgs + 1 });
                }
                else {
                    msg.isRead = true;
                    yield this.chatRef.doc(uid).collection('messages').add(msg);
                }
            }
        });
    }
    getTotalMsg(uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const chatData = yield this.chatRef.doc(uid).valueChanges().pipe(first()).toPromise();
            //// console.log('chatData', chatData);
            return chatData.totalMsgs;
        });
    }
    getMsgs(uid, role) {
        this.getMsgsSub = this.afs.collection('chats').doc(uid).collection('messages', ref => ref.orderBy('createdAt', 'desc').limit(30))
            .snapshotChanges().subscribe(response => {
            if (!response.length) {
                //// console.log('No msgs');
                return false;
            }
            this.allMsgs = [];
            this.firstResponse = response[response.length - 1].payload.doc;
            for (const msg of response) {
                this.allMsgs.push({ msgData: msg.payload.doc.data(), id: msg.payload.doc.id });
                if (role === 'admin') {
                    this.afs.collection('chats').doc(uid).collection('messages').doc(msg.payload.doc.id).update({ isRead: true });
                }
            }
            if (role === 'admin') {
                this.chatRef.doc(uid).update({ unreadMsgs: 0 });
            }
            else {
                this.chatRef.doc(uid).update({ unreadAdminMsgs: 0 });
            }
            this.events.publish('chat:publishMsgs', this.allMsgs.reverse());
        });
    }
    getMoreMsgs(uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.afs.collection('chats').doc(uid).collection('messages', ref => ref.orderBy('createdAt', 'desc')
                .limit(30).startAfter(this.firstResponse)).snapshotChanges().pipe(first()).subscribe(response => {
                if (!response.length) {
                    //// console.log('No msgs');
                    this.events.publish('chat:noMoreMsgs');
                    return false;
                }
                this.firstResponse = response[response.length - 1].payload.doc;
                for (const msg of response) {
                    this.allMsgs.unshift({ msgData: msg.payload.doc.data(), id: msg.payload.doc.id });
                }
                this.events.publish('chat:publishMoreMsgs', this.allMsgs);
            });
        });
    }
    getLastMsgs() {
        //// console.log('in getLastMsgs');
        this.lastMsgsData = [];
        const lastMsgRef = this.afs.collection('chats', ref => ref
            .orderBy('lastMessageAt', 'desc')
            .limit(environment.scrollLimit));
        const lastMsgs = lastMsgRef.snapshotChanges();
        this.lastMsgsSub = lastMsgs.subscribe((res) => {
            this.lastResponseForAdminMsgs = res[res.length - 1].payload.doc;
            for (const msg of res) {
                this.lastMsgsData.push(Object.assign({ id: msg.payload.doc.id }, msg.payload.doc.data()));
            }
            this.events.publish('chat:publishLastMsgs', this.lastMsgsData);
        });
    }
    loadMoreMessagesForAdminHome() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //// console.log('in loadMoreMessagesForAdminHome');
            try {
                this.lastMsgsSub = this.afs.collection('chats', ref => ref
                    .orderBy('lastMessageAt', 'desc')
                    .limit(environment.scrollLimit)
                    .startAfter(this.lastResponseForAdminMsgs))
                    .snapshotChanges()
                    .subscribe((response) => {
                    if (!response.length) {
                        //// console.log('No Data Available');
                        this.events.publish('chat:msgsForAdminHomeLimitReached');
                        return false;
                    }
                    this.lastResponseForAdminMsgs = response[response.length - 1].payload.doc;
                    for (const msg of response) {
                        this.lastMsgsData.push(Object.assign({ id: msg.payload.doc.id }, msg.payload.doc.data()));
                    }
                    this.events.publish('chat:publishLastMsgs', this.lastMsgsData);
                }, error => {
                });
            }
            catch (err) {
                console.dir(err);
            }
        });
    }
    getUnreadMsgOfAdmin(uid) {
        try {
            //// console.log('in getUnreadMsgOfAdmin...');
            if (uid && uid !== undefined) {
                this.afs.collection('chats').doc(uid).valueChanges().subscribe((response) => {
                    //// console.log('getUnreadMsgOfAdmin number', response.unreadAdminMsgs);
                    this.events.publish('chat:publishUnreadMsgOfAdmin', response && response.unreadAdminMsgs ? response.unreadAdminMsgs : 0);
                });
            }
        }
        catch (error) {
            console.dir(error);
        }
    }
    makeadminActiveTrue(uid) {
        this.chatRef.doc(uid).update({ adminActive: true });
    }
    makeadminActiveFalse(uid) {
        this.chatRef.doc(uid).update({ adminActive: false });
    }
    makeUserActiveTrue(uid) {
        this.chatRef.doc(uid).update({ userActive: true });
    }
    makeUserActiveFalse(uid) {
        this.chatRef.doc(uid).update({ userActive: false });
    }
    getPaymentDetails() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const paymentData = yield this.afs.collection('info').doc('payment').valueChanges().pipe(first()).toPromise();
            this.events.publish('chat:publishPaymentDetails', paymentData);
        });
    }
    removeSubscriptions() {
        this.events.unsubscribe('chat:sendMsg');
        this.events.unsubscribe('chat:getMsgs');
        this.events.unsubscribe('chat:getMoreMsgs');
        this.events.unsubscribe('chat:getLastMsgs');
        this.events.unsubscribe('chat:getPaymentDetails');
    }
}
ChatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatService_Factory() { return new ChatService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.UserService)); }, token: ChatService, providedIn: "root" });
