import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@ionic/storage";
export class PriceRequestService {
    constructor(events, afs, storage) {
        this.events = events;
        this.afs = afs;
        this.storage = storage;
    }
    initializeSubscriptions() {
        this.events.subscribe('price-req:sendPriceRequest', (uid) => {
            this.sendPriceRequest(uid);
        });
        this.events.subscribe('price-req:getAllPriceRequests', () => {
            this.getAllPriceRequests();
        });
        this.events.subscribe('price-req:rejectPriceRequest', (uid) => {
            this.rejectPriceRequest(uid);
        });
        this.events.subscribe('price-req:acceptPriceRequest', (uid) => {
            this.acceptPriceRequest(uid);
        });
        // this.events.subscribe('price-req:getUserPriceRequestData', (uid) => {
        //   this.getUserPriceRequestData(uid);
        // });
        this.events.subscribe('price-req:requestForQuote', (name, qty, uid) => {
            this.requestForQuote(name, qty, uid);
        });
        this.events.subscribe('price-req:removePriceRequestsSubs', () => {
            if (this.reqSubs) {
                this.reqSubs.unsubscribe();
            }
        });
    }
    sendPriceRequest(uid) {
        try {
            let priceRequest = firebase.functions().httpsCallable('products-sendPriceRequest');
            priceRequest(uid).then((response) => {
                //// console.log(response);
                if (response.data.status && response.data.status === 'sent') {
                    this.events.publish('price-req:sendPriceRequestSuccess', 'Request has been send succesfully.');
                }
                else if (response.data.status && response.data.status === 'already_sent') {
                    this.events.publish('price-req:sendPriceRequestSuccess', 'Request has been already send.');
                }
                else {
                    this.events.publish('price-req:sendPriceRequestSuccess', 'There is some problem in sending request. Please try again.');
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    }
    getAllPriceRequests() {
        try {
            const reqRef = this.afs.collection('priceRequests', ref => ref.orderBy('createdOn', 'desc')).snapshotChanges().pipe(map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return Object.assign({ id }, data);
            })));
            this.reqSubs = reqRef.subscribe((requests) => {
                this.events.publish('price-req:publishAllPriceRequests', requests);
            });
        }
        catch (error) {
            console.dir(error);
        }
    }
    rejectPriceRequest(uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('priceRequests').doc(uid).update({
                    active: false
                });
                yield this.afs.collection('users').doc(uid).update({
                    showPrices: false
                });
                this.events.publish('price-req:rejectPriceRequestSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    acceptPriceRequest(uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let prAccept = firebase.functions().httpsCallable('products-acceptPriceRequest');
                prAccept(uid).then((response) => {
                    //// console.log(response.data);
                    if (response.data.status === 'accepted') {
                        this.events.publish('price-req:acceptPriceRequestSuccess', 'Prices are now active for this user.');
                    }
                    else {
                        this.events.publish('price-req:acceptPriceRequestSuccess', 'There is some problem in activating prices. Please try again.');
                    }
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    requestForQuote(name, qty, uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const rfqChatMsg = {
                    type: 'txt',
                    createdAt: new Date(),
                    author: 'user',
                    isRead: false,
                    published: true,
                    message: `I am interested in buying ${qty} quantities of "${name}". Please quote for this product.`
                };
                this.events.publish('chat:sendMsg', rfqChatMsg, uid);
                this.events.publish('price-req:requestForQuoteSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
}
PriceRequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PriceRequestService_Factory() { return new PriceRequestService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.Storage)); }, token: PriceRequestService, providedIn: "root" });
