import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { first } from "rxjs/operators";
import { Storage } from "@ionic/storage";
import { ConfigService } from "../config/config.service";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class MultiCountriesService {
  multiCountriesSettings = {
    countries: [
      {
        countryName: "India",
        countryCode: "in",
        currencyCode: "INR",
        dialCode: "+91",
        active: true,
      },
    ],
    settings: {
      autoExchange: false,
      defaultCountry: {
        countryCode: "in",
        countryName: "India",
        currencyCode: "INR",
        dialCode: "+91",
        active: true,
      },
    },
  };
  countryInLogin = {};
  rates = [];
  constructor(
    private afs: AngularFirestore,
    private storage: Storage,
    private configService: ConfigService,
    private http: HttpClient
  ) {}

  async getMultiCountries() {
    try {
      const multiCountriesSettings: any = await this.afs
        .collection("features")
        .doc("multiCountries")
        .valueChanges()
        .pipe(first())
        .toPromise();
      this.multiCountriesSettings = multiCountriesSettings || this.multiCountriesSettings;
      this.multiCountriesSettings.countries = this.multiCountriesSettings.countries.filter(country => country.active === true);
      this.getDefaultCountry();
      this.getExchangeRates();
    } catch (error) {
      console.log(error);
    }
  }

  async setDefaultCountry(country = this.countryInLogin) {
    await this.storage.set("defaultCountry", country);
    this.setConfig(country);
  }

  async getDefaultCountry() {
    const defaultCountry = await this.storage.get("defaultCountry");
    console.log('defaultCountry....', defaultCountry);
    if(defaultCountry && Object.keys(defaultCountry).length) {

      this.setConfig(defaultCountry);
    } else {
      this.setDefaultCountry(this.multiCountriesSettings.settings.defaultCountry);
    }
    return defaultCountry || this.multiCountriesSettings.settings.defaultCountry;
  }

  setConfig(country) {
    this.configService.environment.defaultCountryCode = country.dialCode;
    this.configService.environment.currencyCode = country.currencyCode;
  }

  async getExchangeRates() {
    this.http.get<any>('https://api.exchangerate-api.com/v4/latest/INR').subscribe(response => {
      console.log('response', response);
      this.rates = response.rates;
    });
  }
}
