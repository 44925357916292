import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { WidgetsService } from 'src/app/services/widgets/widgets.service';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
    selector: 'app-widget-form',
    templateUrl: './widget-form.component.html',
    styleUrls: ['./widget-form.component.scss'],
})
export class WidgetFormComponent implements OnInit {
    @Input() widgetID: any;
    @Input() source = 'page';

    formData: any = {
        formTitle: '',
        banner: { mob: '', thumb: '', url: '' },
        inputs: [],
        buttonName: '',
        multipleSubmissions: false
    };

    dynamicForm: FormGroup;
    isSubmitted: boolean = false;
    CO_WIDGET_FORM_LABELS: any;
    widgetStyle:any;
    background:any ;
    response;
    formType:string;

    constructor(private widgetsService: WidgetsService,
        private sharedService: SharedService,
        private labelService: LabelService,
        private domSanitizer:DomSanitizer,
        private navCtrl: NavController,
        private modalController: ModalController) { }

    async ngOnInit() {
        this.CO_WIDGET_FORM_LABELS = this.labelService.labels['CO_WIDGET_FORM'];

        let widgetData:any = await this.widgetsService.getWidgetData(this.widgetID);
        if (widgetData) {
            for (const key of Object.keys(this.formData)) {
                this.formData[key] = widgetData[key];
            }

            if (widgetData && widgetData.style && Object.keys(widgetData.style).length != 0) {
                this.widgetStyle = widgetData.style;
                if(this.widgetStyle.backgroundImage){
                    this.background = this.domSanitizer.bypassSecurityTrustStyle(`url(${this.widgetStyle.backgroundImage})`);
                }
                else if(this.widgetStyle.backgroundColor){
                    this.background = this.widgetStyle.backgroundColor;
                }
                else{
                    //this.background = 'transparent';
                }
            }
            
            this.formType = "formType" in widgetData ? widgetData.formType : "others";
        }

        //check if form is already submitted by user or not
        const response = await this.widgetsService.checkFormAlreadySubmitted(this.formData.multipleSubmissions);
        this.response = response;
        if (response === 'not_submitted') {
            //making form controls
            if (this.formData.formTitle) {
                let group = {};
                this.formData.inputs.forEach(input => {
                    const validators = [];
                    if (input.required) {
                        validators.push(Validators.required);
                    }
                    if (input.type === 'email') {
                        validators.push(Validators.email);
                    }
                    group[input.name] = new FormControl('', validators);
                });
                this.dynamicForm = new FormGroup(group);
            }
        } else {
            let msg = '';
            switch (response) {
                case 'error':
                    msg = this.labelService.labels['SHARED']['some_issue_msg'];
                    break;
                case 'submitted':
                    msg = this.CO_WIDGET_FORM_LABELS['already_submitted'];
                    break;
                // case 'no_login':
                //     msg = this.CO_WIDGET_FORM_LABELS['login_to_continue'];
                //     break;
            }
            if (msg!='') {
                this.sharedService.presentAlert(msg);
                if(this.source === 'page') {
                    this.navCtrl.navigateRoot(['']);
                } else {
                    this.modalController.dismiss();
                }
            }
        }
    }

    getControl(formControlName: string) {
        return this.dynamicForm.get(formControlName);
    }

    async onSubmit() {
        if (this.response == 'no_login') {
            this.sharedService.presentAlert(this.CO_WIDGET_FORM_LABELS['login_to_continue']);
            return;
        }
        this.isSubmitted = true;
        if (!this.dynamicForm.valid) {
            return;
        } else {
            await this.sharedService.presentLoading(1000000);
            const data = {
                formData: this.dynamicForm.value,
                widgetId: this.widgetID,
                userId: await this.sharedService.getStorageUid(),
                createdAt: new Date(),
                formType: this.formType,
                name: await this.sharedService.getStorageUserName(),
            }
            const response = await this.widgetsService.submitForm(data);
            this.sharedService.loading.dismiss();
            if (response) {
                this.sharedService.presentAlert(this.CO_WIDGET_FORM_LABELS['submitted_msg']);
                if(this.source === 'page') {
                    this.navCtrl.navigateRoot(['']);
                } else {
                    this.modalController.dismiss();
                }
            } else {
                this.sharedService.presentAlert(this.CO_WIDGET_FORM_LABELS['issue_in_submitting_form']);
            }
        }
    }

    getBannerSrc() {
        return this.formData.banner.mob ? 'mob' : 'url';
    }

    isValid(name: string) {
        const control = this.getControl(name);
        return this.isSubmitted && (control.errors && (control.errors.required || control.errors.email)) ? false : true;
    }

    resetForm() {
        this.isSubmitted = false;
        this.dynamicForm.reset();
    }


    async chooseFile(name: string, files: FileList) {
        console.log('files', files);
        for (let i = 0; i < files.length; i++) {
            const base64Image = await this.readBase64(files.item(i));
            const control = this.getControl(name);
            control.setValue(base64Image);
        }
    }

    readBase64(file) {
        return new Promise(async (resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = (event: any) => { // called once readAsDataURL is completed
                resolve(event.target.result);
            }
        });
    }

}