import { AdminSettingsService } from './../services/admin-settings/admin-settings.service';
import { SharedService } from './../services/shared/shared.service';
import { HomePageModule } from './../home/home.module';
import { ServicesFeatureService } from './../services/services-feature/services-feature.service';
import { BrandsService } from './../services/brands/brands.service';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Events, MenuController, Platform, ToastController, ModalController } from '@ionic/angular';
import { Router, NavigationExtras } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { ProductService } from '../services/product/product.service';
import { environment } from 'src/environments/environment';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { WalletService } from '../services/wallet/wallet.service';
import { HomePage } from '../home/home.page';
import {ChatBotPage} from '../chat-bot/chat-bot.page';
import { ConfigService } from '../services/config/config.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ReferralService } from '../services/referral/referral.service';
import { encodeURL } from '../services/parseURL';
import { ProductSubscriptionsService } from '../services/product-subscriptions/product-subscriptions.service';
import { DesignStudioService } from '../services/design-studio/design-studio.service';
import { ConsoleService } from '../services/console/console.service';



@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.page.html',
  styleUrls: ['./sidemenu.page.scss'],
})
export class SidemenuPage implements OnInit {
  navigate: any;
  userName: string;
  userPic: string;
  showCategories:boolean = false;
  categoryPlusClicked: boolean = false;
  categoriesData = [];
  loginStatus: boolean = false;
  role: string = 'user';
  storeName: string;
  storePhone: string;
  appVersionNumber: string;
  isPriceReqFeature: boolean;
  listOfSubcategories: any = {};
  isAndroid: boolean;
  appStoreUrl: string = '';
  playstoreUrl: string = '';
  isWalletActive: boolean = false;
  listOfSubcategoriesInView: any = {};
  showBrands = false;
  brands = [];

  brandPlusClicked = false;
  isServiceActive = false;
  showCategoriesLoader = false;
  showBrandsLoader = false;
  subscriptionFeature = false;
  RFQFeature = false;
  isReferralActive = false;
  isMembership = false;
  appointmentFeature: any;
  headerData:any;
  constructor(private storage: Storage, private events: Events, private router: Router,
              private userService: UserService, private productService: ProductService,
              private menuCtrl: MenuController,
              private socialSharing: SocialSharing,
              private platform: Platform,
              private toastController: ToastController,
              private walletService: WalletService,
              private brandsService: BrandsService,
              private servicesFeatureService: ServicesFeatureService,
              private modalController: ModalController,
              private sharedService: SharedService,
              private configService: ConfigService,
              private utilsService:UtilsService,
              private referralService: ReferralService,
              private adminSettingsService: AdminSettingsService,
              private designStudioService:DesignStudioService,
              private consoleService:ConsoleService,
              private productSubscriptionsService: ProductSubscriptionsService) {
  }

  async ngOnInit() {
    this.headerData = await this.designStudioService.getSessionStorageHeaderData();
    this.consoleService.consoleLog('sidemenu:85 headerdata', this.headerData)
  }
  async menuOpen() {
    this.storage.get('appUrls').then((urls) => {
      if (urls) {
        if (this.platform.is('ios')) {
          this.isAndroid = false;
          this.appStoreUrl = urls.appStoreUrl;
        } else {
          this.isAndroid = true;
          this.playstoreUrl = urls.playstoreUrl;
        }
      }
    });

    this.storage.get('storeInfo').then((data) => {
      this.storeName = data.storeName;
      this.storePhone = data.storePhone;
    });

    this.getUserData();
    setTimeout(() => {
    let userId = this.userService.getUserId();
    if(userId === '') {
      this.loginStatus = false;
    } else {
      this.loginStatus = true;
    }
    }, 100);
    this.isPriceReqFeature = this.configService.environment.priceReqFeature;
    const walletSettings: any = await this.walletService.getWalletSettings('sidemenu');
    this.isWalletActive = walletSettings && walletSettings.active ? walletSettings.active : false;
    const serviceStatus: any = await this.servicesFeatureService.getServicesActiveStatus('sidemenu');
    this.isServiceActive = serviceStatus && serviceStatus.isActive ? serviceStatus.isActive : false;
    if(this.configService.environment.subscriptionFeature) {
      const subSettings: any = await this.productSubscriptionsService.getSettings('return');
      this.subscriptionFeature = subSettings && subSettings.isActive ? true : false;
    }
    this.RFQFeature = this.configService.environment.RFQFeature;
    const referralSettings: any = await this.referralService.getReferralSettings('sidemenu');
    this.isReferralActive = referralSettings && referralSettings.active ? referralSettings.active : false;
    this.isMembership = this.configService.environment.membership;
    this.appointmentFeature = this.configService.environment.appointmentFeature;

    this.brands = await this.brandsService.getAllBrandsForSideMenu() || [];
  }
  menuClose() {
  }
  getUserData() {
    this.storage.get('userName').then((name) => {
      this.userName = name;
    });
    this.storage.get('userPic').then((url) => {
      this.userPic = url;
    });
    this.storage.get('userRole').then((role) => {
      if(!role) {
        this.role = 'user';
      } else {
        this.role = role;
      }
    });
  }
  goToPage(page: string) {
    this.menuCtrl.close();
    this.router.navigate([page]);
  }

  async getAllCategories() {
    if(this.categoryPlusClicked === false) {
      this.showCategories = true;
      this.categoriesData = await this.productService.getAllCategoriesForSideMenu();
      //// console.log('categoriesData', this.categoriesData);
      this.categoryPlusClicked = true;
    } else {
      this.showCategories = false;
      this.categoryPlusClicked = false;
    }

  }
  async getSubCategories(cid) {
    if(!this.listOfSubcategories.hasOwnProperty(cid)) {
      let subcategories = []
      subcategories = await this.productService.getSubcategoriesInNewProduct(cid);
      this.listOfSubcategories[cid] = subcategories;
      //// console.log('listOfSubcategories', this.listOfSubcategories);
      this.listOfSubcategoriesInView[cid] = {active: true};
    } else {
      if(!this.listOfSubcategoriesInView[cid].active) {
        this.listOfSubcategoriesInView[cid].active = true;
      } else {
        this.listOfSubcategoriesInView[cid].active = false;
      }
    }
  }
  urlSlug(value) {
    return encodeURL(value);
  }

  onClickCategory(category) {
    let cname = this.urlSlug(category.name);
    // this.sharedService.manageExclusiveCategories(category.isExclusive || false, category.id, 'parentCategory');
    if(category.isSubcategories) {
      this.router.navigate([`shop-subcategories/${cname}/${category.id}`]);
    } else {
      this.router.navigate([`shop/category/${cname}/${category.id}`]);
    }
  }

  async onClickSubcategory(category, subcat) {
    // let exclusiveObj = await this.storage.get('exclusiveObj');
    // const isExclusive = exclusiveObj.categoryType === 'parentCategory' && exclusiveObj.exclusivity ? false : category.isExclusive;
    // this.sharedService.manageExclusiveCategories(isExclusive || false, category.id, 'subCategory');
    this.router.navigate(['/shop/category/', this.urlSlug(category.name), category.id, this.urlSlug(subcat.name), subcat.id]);
  }

  async getAllBrands() {
    if (this.brandPlusClicked === false) {
      this.showBrandsLoader = true;
      if (!this.brands.length) {
        this.brands = await this.brandsService.getAllBrandsForSideMenu();
      }
      this.showBrands = true;
      this.brandPlusClicked = true;
      this.showBrandsLoader = false;
    } else {
      this.showBrands = false;
      this.brandPlusClicked = false;
    }
  }

  onClickBrand(brand) {
    this.menuCtrl.close();
    let brandname = encodeURL(brand.name);
    this.router.navigate([`shop/brand/${brandname}/${brand.id}`]);
    //this.router.navigate(['shop'], navigationExtras);
  }

  logout() {
    this.menuCtrl.close();
    this.events.publish('auth:logout');
    this.events.publish('makeUnreadAdminMsgsZero');
  }

    async openLoginModal() {
    this.menuCtrl.close();
    this.utilsService.openLoginModal();
  }



  async openChatModal(){
    const modal = await this.modalController.create({
       component: ChatBotPage,
       cssClass: "chat-modal",
       showBackdrop:false,
       backdropDismiss:false
     });
     return await modal.present();
 }

  shareApp() {
    let appUrl = '';
    if (this.isAndroid) {
      appUrl = this.playstoreUrl;
    } else {
      appUrl = this.appStoreUrl;
    }
    if (appUrl) {
      this.socialSharing.share(this.storeName + ' link:', '', this.configService.environment.appSharingImg, appUrl).then(() => {
        //// console.log('app sharing works!');
      }) .catch(() => {
        //// console.log('app sharing not works!');
      });
    } else {
      this.presentToast('There is some problem in sharing of app.');
    }
  }



  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

//   toggleAccordian(event, index) {
//     var element = event.target;
//     console.log(element)
//     element.classList.toggle("active");
//     // if(this.data[index].isActive) {
//     //   this.data[index].isActive = false;
//     // } else {
//     //   this.data[index].isActive = true;
//     // }
//     var panel = element.nextElementSibling;
//     if (panel.style.maxHeight) {
//       panel.style.maxHeight = null;
//     } else {
//       panel.style.maxHeight = panel.scrollHeight + "px";
//     }
// }

toggleAccordian(type, i) {
  let item = document.getElementById(`${type}-item-${i}`);
  item.classList.toggle("active");

  let panel = document.getElementById(`${type}-menu-${i}`);
  panel.classList.toggle("active");
}

}
