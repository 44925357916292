import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { BookingSchedulePage } from 'src/app/booking/booking-schedule/booking-schedule.page';
import { SelectAddonPage } from 'src/app/pages/select-addon/select-addon.page';
import { convertSnaps } from '../db-utils';
import { SharedService } from '../shared/shared.service';
import { VendorService } from '../vendor/vendor.service';
import { CartService } from '../cart/cart.service';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../shared/shared.service";
import * as i3 from "../vendor/vendor.service";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/router";
import * as i6 from "../cart/cart.service";
import * as i7 from "@ionic/storage";
export class BookingService {
    constructor(afs, sharedService, vendorService, modalController, router, cartService, storage) {
        this.afs = afs;
        this.sharedService = sharedService;
        this.vendorService = vendorService;
        this.modalController = modalController;
        this.router = router;
        this.cartService = cartService;
        this.storage = storage;
        this.userBookings$ = new BehaviorSubject([]);
    }
    confirmBooking(data, slotLimit) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    //check slot availability
                    let slotAvailable = false;
                    if (data.schedule && data.schedule.active) {
                        let bookings = [];
                        bookings = yield this.afs.collection('bookings', ref => ref
                            .where('item.id', '==', data.item.id)
                            .where('schedule.date', '==', data.schedule.date)
                            .where('schedule.slot.start', '==', data.schedule.slot.start)
                            .where('schedule.slot.end', '==', data.schedule.slot.end)
                            .where('status', '==', 'accepted'))
                            .valueChanges().pipe(first()).toPromise();
                        console.log('bookings', bookings);
                        if (bookings.length < slotLimit) {
                            if (this.vendorService.vendorSettings.isMultiVendorAvailable) {
                                if (data.vendor.id) {
                                    const vendorDetails = yield this.vendorService.getVendorData(data.vendor.id);
                                    if (vendorDetails && 'appointmentSlotLimit' in vendorDetails && vendorDetails.appointmentSlotLimit) {
                                        const vendorBookings = yield this.afs.collection('bookings', ref => ref
                                            .where('vendor.id', '==', data.vendor.id)
                                            .where('schedule.date', '==', data.schedule.date)
                                            .where('schedule.slot.start', '==', data.schedule.slot.start)
                                            .where('schedule.slot.end', '==', data.schedule.slot.end)
                                            .where('status', '==', 'accepted'))
                                            .valueChanges().pipe(first()).toPromise();
                                        if (vendorBookings.length < vendorDetails.appointmentSlotLimit) {
                                            slotAvailable = true;
                                        }
                                        else {
                                            slotAvailable = false;
                                        }
                                    }
                                    else {
                                        slotAvailable = true;
                                    }
                                }
                                else {
                                    slotAvailable = true;
                                }
                            }
                            else {
                                slotAvailable = true;
                            }
                        }
                        else {
                            slotAvailable = false;
                        }
                    }
                    else {
                        slotAvailable = true;
                    }
                    if (slotAvailable) {
                        const bookingRef = yield this.afs.collection('bookings').add(data);
                        resolve({ status: 'added', id: bookingRef.id });
                    }
                    else {
                        resolve({ status: 'not_available', id: '' });
                    }
                }
                catch (error) {
                    console.log(error);
                    resolve({ status: 'error', id: '' });
                }
            }));
        });
    }
    getUserBookings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const userId = yield this.sharedService.getStorageUid();
                    this.userBookingsSub = yield this.afs.collection('bookings', ref => ref
                        .orderBy('createdAt', 'desc')
                        .where('user.id', '==', userId))
                        .snapshotChanges()
                        .pipe(map(snaps => convertSnaps(snaps)))
                        .subscribe(bookings => {
                        resolve(bookings);
                        this.userBookings$.next([...bookings]);
                    });
                }
                catch (error) {
                    console.log(error);
                    resolve([]);
                }
            }));
        });
    }
    startBookingProcess(product) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isCartAvailable = yield this.cartService.checkCartAvailability(product.inactiveByVendor);
            if (!isCartAvailable) {
                return;
            }
            const uid = yield this.storage.get('uid');
            if (!uid) {
                this.sharedService.openLoginModal();
                return;
            }
            let addOns = { totalPrice: 0, selectedOptions: [] };
            if (product.templateId) {
                yield this.sharedService.presentLoading();
                const template = yield this.getTemplateData(product.templateId);
                this.sharedService.dismissLoading();
                if (!template) {
                    this.openBookingSchedule(product, addOns);
                    return;
                }
                const modal = yield this.modalController.create({
                    component: SelectAddonPage,
                    componentProps: {
                        item: {
                            name: product.prodName,
                            price: product.discountedPrice,
                            id: product.id,
                            templateId: product.templateId,
                            description: product.prodShortDesc,
                            mrp: product.prodPrice
                        },
                        template
                    }
                });
                modal.onDidDismiss().then(res => {
                    if (res && res.data && !res.data.closed) {
                        addOns = res.data;
                        this.openBookingSchedule(product, addOns);
                    }
                });
                yield modal.present();
            }
            else {
                this.openBookingSchedule(product, addOns);
            }
        });
    }
    openBookingSchedule(product, addOns) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: BookingSchedulePage,
                cssClass: 'custom-modal',
                componentProps: {
                    data: {
                        item: {
                            name: product.prodName,
                            price: product.discountedPrice,
                            coverPic: product.coverPic,
                            id: product.id,
                            allowPayment: product.allowPayment || false,
                            allowAddress: product.allowAddress || false,
                            addOns,
                            gst: {
                                value: product.gst || 0,
                                isExclusive: product.gstExclusive || false
                            },
                            additionalInfo: product.additionalInfo ? product.additionalInfo : null,
                            shortDescription: product.prodShortDesc
                        },
                        vendor: {
                            id: product.vendorId || ''
                        },
                        scheduleData: product.scheduleData
                    }
                }
            });
            yield modal.present();
        });
    }
    getBookingData(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const bookingData = yield this.afs.collection('bookings').doc(id).valueChanges().pipe(first()).toPromise();
            return bookingData || {};
        });
    }
    getTemplateData(templateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const templateData = yield this.afs.collection('templates').doc(templateId).valueChanges().pipe(first()).toPromise();
            return templateData || null;
        });
    }
}
BookingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BookingService_Factory() { return new BookingService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.SharedService), i0.ɵɵinject(i3.VendorService), i0.ɵɵinject(i4.ModalController), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i6.CartService), i0.ɵɵinject(i7.Storage)); }, token: BookingService, providedIn: "root" });
