import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events, AlertController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase';
import { CartService } from '../cart/cart.service';
import { ConfigService } from '../config/config.service';
import { SharedService } from '../shared/shared.service';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  verificationId: any;
  routeFromLoginPage: boolean = false;
  loggedOut: boolean = false;
  constructor(private afs: AngularFirestore, private fireAuth: AngularFireAuth, private events: Events,
              private userService: UserService, private firebaseAuthentication: FirebaseAuthentication, private router: Router,
              private ngZone: NgZone,private storage: Storage,
              private cartService: CartService,
              private configService: ConfigService,
              private alertController: AlertController,
              private sharedService: SharedService) { }
  initializeSubscriptions() {


    this.events.subscribe('auth:login', (phoneNo) => {
      this.login(phoneNo);
    });
    this.events.subscribe('auth:sendotp', (otp) => {
      this.sendotp(otp);
    });
    this.events.subscribe('auth:checkUserStatus', (phoneNo) => {
      this.checkUserStatus(phoneNo);
    });
    this.fireAuth.authState.subscribe(async (user) => {
      this.cartService.setCartProducts();

      if (user) {

        this.storage.set('uid', user.uid);
        this.userService.addUserId(user.uid);
        this.userService.addPhoneNo(user.phoneNumber);
        const usersData: any = await this.afs.doc(`users/${user.uid}`).valueChanges().pipe(first()).toPromise();
    
        if (usersData) {
          this.storage.set('userName', usersData.name);
          this.storage.set('userPic', usersData.dP);
          this.storage.set('userRole', usersData.role);
          this.storage.set('userDefaultAddress', usersData.defaultAddress);
          if(this.configService.environment.priceReqFeature) {
            if(usersData.hasOwnProperty('showPrices')) {
              this.storage.set('showPrices', usersData.showPrices);
            } else {
              this.storage.set('showPrices', false);
            }
          }
          if (this.configService.environment.appAccessOnApproval) {
            this.storage.set('accessByAdmin', 'accessByAdmin' in usersData ? usersData.accessByAdmin : false);
          }
          if (usersData.hasOwnProperty('subRole')) {
            this.storage.set('userSubRole', usersData.subRole);
          }
          this.userService.addUserName(usersData.name);
          if (usersData.email) {
            this.userService.addUserEmail(usersData.email);
          }

          this.saveMetaData(user.uid);

        }
        this.events.publish('wishlist:getWishlist');
        this.cartService.setCartProducts();
      } else {
        if (this.configService.environment.priceReqFeature) {
          this.storage.set('showPrices', false);
        }
      }

      setTimeout(async () => {
        if(this.configService.environment.appAccessOnApproval) {
          await this.sharedService.checkForAppAccesss();
        }
      }, 2000);
      
      this.events.publish('admin-settings:getStoreInfo');
      this.events.publish('auth:hideSplashScreen');
    });
    this.events.subscribe('auth:logout', () => {
      this.loggedOut = true;
      this.logout();
       });

    this.events.subscribe('auth:fbLogin', (phoneNo) => {
      this.fbLogin(phoneNo);
    });
    this.events.subscribe('auth:googleLogin', (phoneNo) => {
      this.googleLogin(phoneNo);
    });
  }

  async saveMetaData(uid: string) {
    this.afs.doc(`users/${uid}`).update({ lastAccessAt: new Date() });

    const analytics = {
        lastAccessAt: new Date(),
      };
    this.afs.collection('users').doc(uid).collection('analytics').doc('access').collection('data').doc('web').set(analytics);
}

  async checkUserStatus(phoneNo: string) {
    try {
    const checkUserExistenceByPhone = firebase.functions().httpsCallable('users-checkUserExistenceByPhone');
    checkUserExistenceByPhone(phoneNo).then((res) => {
      const resData = res.data;

      if (resData.isExist) {
        this.events.publish('auth:phoneAlreadyInUse', resData.mode);
      } else {
        this.events.publish('auth:newUser');
      }
    });
    } catch (error) {
      console.dir(error);
    }

  }

  fbLogin(phoneNo) {
    const provider = new firebase.auth.FacebookAuthProvider();
    this.socialSignin(provider, phoneNo, 'facebook');
  }
  googleLogin(phoneNo) {
    const provider = new firebase.auth.GoogleAuthProvider();
    this.socialSignin(provider, phoneNo, 'google');
  }

  async socialSignin(provider, phoneNo, type) {
    firebase.auth().signInWithPopup(provider).then((result: any) => {
      const token = result.credential.accessToken;
      const user = result.user;
      this.userService.socialSignInUserCheck(user.uid, user.displayName, user.email, phoneNo, type);
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(errorMessage);
      const email = error.email;
      const credential = error.credential;
    });
  }
  
  async login(phoneNo) {
   
    this.firebaseAuthentication.verifyPhoneNumber(phoneNo, 0).then((verificationId) => {
        this.verificationId = verificationId;
      
        this.events.publish('auth:gotVerificationId');
        this.userService.addPhoneNo(phoneNo);
        this.routeFromLoginPage = true;
    }).catch((err) => {
     console.log(err);
      if(err = "We have blocked all requests from this device due to unusual activity. Try again later.") {
        this.events.publish('auth:blockUserForTooManyOTP');
      }
    });
  }
  async sendotp(otp) {
     var credential =  await firebase.auth.PhoneAuthProvider.credential(this.verificationId, otp);
     firebase.auth().signInWithCredential(credential).then((res) => {
       this.userService.checkUserAlreadyExistsOrNot(res.user.uid);
     }).catch((err) => {
        if(err === "The user account has been disabled by an administrator.") {
          this.events.publish('auth:incorrectOTP', 'Admin has blocked you!');
        } else {
          this.events.publish('auth:incorrectOTP', 'OTP did not match');
        }
      });
  }
  logout() {
    try {
      this.storage.get('storeInfo').then((storeInfo) => {
            this.storage.get('checkPincode').then((isPincodeChecked) => {
                this.storage.get('deliverySettings').then(deliverySettings => {
                    this.storage.clear();
                    this.storage.set('storeInfo', storeInfo);
                    this.storage.set('checkPincode', isPincodeChecked);
                    this.storage.set('deliverySettings', deliverySettings);
                    this.ngZone.run(() => {
                        this.router.navigate(['']);;
                    });
                    
                });
            });
    });
    this.fireAuth.auth.signOut();
    this.firebaseAuthentication.signOut();
    this.userService.addUserId('');
    this.userService.addPhoneNo('');
    this.cartService.cartProducts = [];
    this.cartService.cartProductsUpdated.next([]);
    this.events.publish('auth:logoutSuccess');
    this.logoutAlert();
  } catch (err) {
    console.dir(err);
    }
  }

  async logoutAlert() {
    const alert = await this.alertController.create({
      message: 'You logout successfully',
      buttons: ['OK']
    });
  
    await alert.present();
  }

  


}
