import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { WidgetsService } from 'src/app/services/widgets/widgets.service';
import { DomSanitizer } from '@angular/platform-browser';
export class WidgetFormComponent {
    constructor(widgetsService, sharedService, labelService, domSanitizer, navCtrl, modalController) {
        this.widgetsService = widgetsService;
        this.sharedService = sharedService;
        this.labelService = labelService;
        this.domSanitizer = domSanitizer;
        this.navCtrl = navCtrl;
        this.modalController = modalController;
        this.source = 'page';
        this.formData = {
            formTitle: '',
            banner: { mob: '', thumb: '', url: '' },
            inputs: [],
            buttonName: '',
            multipleSubmissions: false
        };
        this.isSubmitted = false;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.CO_WIDGET_FORM_LABELS = this.labelService.labels['CO_WIDGET_FORM'];
            let widgetData = yield this.widgetsService.getWidgetData(this.widgetID);
            if (widgetData) {
                for (const key of Object.keys(this.formData)) {
                    this.formData[key] = widgetData[key];
                }
                if (widgetData && widgetData.style && Object.keys(widgetData.style).length != 0) {
                    this.widgetStyle = widgetData.style;
                    if (this.widgetStyle.backgroundImage) {
                        this.background = this.domSanitizer.bypassSecurityTrustStyle(`url(${this.widgetStyle.backgroundImage})`);
                    }
                    else if (this.widgetStyle.backgroundColor) {
                        this.background = this.widgetStyle.backgroundColor;
                    }
                    else {
                        //this.background = 'transparent';
                    }
                }
                this.formType = "formType" in widgetData ? widgetData.formType : "others";
            }
            //check if form is already submitted by user or not
            const response = yield this.widgetsService.checkFormAlreadySubmitted(this.formData.multipleSubmissions);
            this.response = response;
            if (response === 'not_submitted') {
                //making form controls
                if (this.formData.formTitle) {
                    let group = {};
                    this.formData.inputs.forEach(input => {
                        const validators = [];
                        if (input.required) {
                            validators.push(Validators.required);
                        }
                        if (input.type === 'email') {
                            validators.push(Validators.email);
                        }
                        group[input.name] = new FormControl('', validators);
                    });
                    this.dynamicForm = new FormGroup(group);
                }
            }
            else {
                let msg = '';
                switch (response) {
                    case 'error':
                        msg = this.labelService.labels['SHARED']['some_issue_msg'];
                        break;
                    case 'submitted':
                        msg = this.CO_WIDGET_FORM_LABELS['already_submitted'];
                        break;
                    // case 'no_login':
                    //     msg = this.CO_WIDGET_FORM_LABELS['login_to_continue'];
                    //     break;
                }
                if (msg != '') {
                    this.sharedService.presentAlert(msg);
                    if (this.source === 'page') {
                        this.navCtrl.navigateRoot(['']);
                    }
                    else {
                        this.modalController.dismiss();
                    }
                }
            }
        });
    }
    getControl(formControlName) {
        return this.dynamicForm.get(formControlName);
    }
    onSubmit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.response == 'no_login') {
                this.sharedService.presentAlert(this.CO_WIDGET_FORM_LABELS['login_to_continue']);
                return;
            }
            this.isSubmitted = true;
            if (!this.dynamicForm.valid) {
                return;
            }
            else {
                yield this.sharedService.presentLoading(1000000);
                const data = {
                    formData: this.dynamicForm.value,
                    widgetId: this.widgetID,
                    userId: yield this.sharedService.getStorageUid(),
                    createdAt: new Date(),
                    formType: this.formType,
                    name: yield this.sharedService.getStorageUserName(),
                };
                const response = yield this.widgetsService.submitForm(data);
                this.sharedService.loading.dismiss();
                if (response) {
                    this.sharedService.presentAlert(this.CO_WIDGET_FORM_LABELS['submitted_msg']);
                    if (this.source === 'page') {
                        this.navCtrl.navigateRoot(['']);
                    }
                    else {
                        this.modalController.dismiss();
                    }
                }
                else {
                    this.sharedService.presentAlert(this.CO_WIDGET_FORM_LABELS['issue_in_submitting_form']);
                }
            }
        });
    }
    getBannerSrc() {
        return this.formData.banner.mob ? 'mob' : 'url';
    }
    isValid(name) {
        const control = this.getControl(name);
        return this.isSubmitted && (control.errors && (control.errors.required || control.errors.email)) ? false : true;
    }
    resetForm() {
        this.isSubmitted = false;
        this.dynamicForm.reset();
    }
    chooseFile(name, files) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('files', files);
            for (let i = 0; i < files.length; i++) {
                const base64Image = yield this.readBase64(files.item(i));
                const control = this.getControl(name);
                control.setValue(base64Image);
            }
        });
    }
    readBase64(file) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                resolve(event.target.result);
            };
        }));
    }
}
