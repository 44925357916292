import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadScriptsService } from 'src/app/services/load-scripts/load-scripts.service';
import { OrderService } from 'src/app/services/order/order.service';
import { WalletService } from 'src/app/services/wallet/wallet.service';

declare var Cashfree: any;

@Component({
  selector: 'app-cashfree',
  templateUrl: './cashfree.page.html',
  styleUrls: ['./cashfree.page.scss'],
})
export class CashfreePage implements OnInit {
    
  showLoader = true;
  orderData;
  autoConfirm;
  walletTopup;

  constructor(private orderService: OrderService,
    private modalController: ModalController,
    private loadScriptsService: LoadScriptsService,
    private walletService: WalletService) {
      this.loadScriptsService.loadPaymentLibraries(); }

      async ngOnInit() {
      const cashfree = new Cashfree();
      const paymentDom = document.getElementById("payment-form");
      let success, failure, token;

      if (this.walletTopup) {
        token = await this.walletService.createOrderCashfree(this.orderData);
        success = async (data) => {
              if (data.order && data.order.status == "PAID") {
                    this.closeModal();
                    await this.walletService.getOrderDetailsCashfree(data.order.orderId, this.orderData);
              } else {
                console.log('inside else of not paid');
                    this.closeModal();
                    this.walletService.presentFailureAlert();
              } 
        }
        failure = (data) => {
          console.log('inisde failure');
                this.closeModal();
                this.walletService.presentFailureAlert();
        }
      } else{
        const {cashfreeToken, orderCashfree} = await this.orderService.createOrderCashfree(this.orderData, this.autoConfirm);
        token = cashfreeToken;
        console.log('token:', cashfreeToken);
        this.orderData = orderCashfree;
        success = async (data) => {
              if (data.order && data.order.status == "PAID") {
                    this.closeModal();
                    //this.cashfreeService.paymentSuccess({...this.orderData, orderId: data.order.orderId});
                    await this.orderService.getOrderDetailsCashfree(data.order.orderId, this.orderData, this.autoConfirm);
              } else {
                    this.closeModal();
                    this.orderService.cashfreePaymentFailed(this.autoConfirm, this.orderData);
                    this.orderService.presentFailureAlert();
              } 
        }
        failure = (data) => {
                this.closeModal();
                this.orderService.cashfreePaymentFailed(this.autoConfirm, this.orderData);
                this.orderService.presentFailureAlert();
        }
      }
      const dropConfig = {
          "components": [
              "order-details",
              "card",
              "netbanking",
              "app",
              "upi-collect",
              "upi-intent"
          ],
          "orderToken": token,
          "onSuccess": success,
          "onFailure": failure,
          "style": {
              "backgroundColor": "#ffffff",
              "color": "#11385b",
              "fontFamily": "Lato",
              "fontSize": "14px",
              "errorColor": "#ff0000",
              "theme": "light", //(or dark)
          }
      }
      this.showLoader = false;
      cashfree.initialiseDropin(paymentDom, dropConfig);
    }
  
  closeModal() {
    this.modalController.dismiss();
  }

}
