import * as tslib_1 from "tslib";
import { AngularFirestore } from "@angular/fire/firestore";
import { first } from "rxjs/operators";
import { Storage } from "@ionic/storage";
import { ConfigService } from "../config/config.service";
import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/storage";
import * as i3 from "../config/config.service";
import * as i4 from "@angular/common/http";
export class MultiCountriesService {
    constructor(afs, storage, configService, http) {
        this.afs = afs;
        this.storage = storage;
        this.configService = configService;
        this.http = http;
        this.multiCountriesSettings = {
            countries: [
                {
                    countryName: "India",
                    countryCode: "in",
                    currencyCode: "INR",
                    dialCode: "+91",
                    active: true,
                },
            ],
            settings: {
                autoExchange: false,
                defaultCountry: {
                    countryCode: "in",
                    countryName: "India",
                    currencyCode: "INR",
                    dialCode: "+91",
                    active: true,
                },
            },
        };
        this.countryInLogin = {};
        this.rates = [];
    }
    getMultiCountries() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const multiCountriesSettings = yield this.afs
                    .collection("features")
                    .doc("multiCountries")
                    .valueChanges()
                    .pipe(first())
                    .toPromise();
                this.multiCountriesSettings = multiCountriesSettings || this.multiCountriesSettings;
                this.multiCountriesSettings.countries = this.multiCountriesSettings.countries.filter(country => country.active === true);
                this.getDefaultCountry();
                this.getExchangeRates();
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    setDefaultCountry(country = this.countryInLogin) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.storage.set("defaultCountry", country);
            this.setConfig(country);
        });
    }
    getDefaultCountry() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const defaultCountry = yield this.storage.get("defaultCountry");
            console.log('defaultCountry....', defaultCountry);
            if (defaultCountry && Object.keys(defaultCountry).length) {
                this.setConfig(defaultCountry);
            }
            else {
                this.setDefaultCountry(this.multiCountriesSettings.settings.defaultCountry);
            }
            return defaultCountry || this.multiCountriesSettings.settings.defaultCountry;
        });
    }
    setConfig(country) {
        this.configService.environment.defaultCountryCode = country.dialCode;
        this.configService.environment.currencyCode = country.currencyCode;
    }
    getExchangeRates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.http.get('https://api.exchangerate-api.com/v4/latest/INR').subscribe(response => {
                console.log('response', response);
                this.rates = response.rates;
            });
        });
    }
}
MultiCountriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MultiCountriesService_Factory() { return new MultiCountriesService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.HttpClient)); }, token: MultiCountriesService, providedIn: "root" });
