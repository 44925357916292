import * as tslib_1 from "tslib";
import { AdminSettingsService } from './../services/admin-settings/admin-settings.service';
import { SharedService } from './../services/shared/shared.service';
import { ServicesFeatureService } from './../services/services-feature/services-feature.service';
import { BrandsService } from './../services/brands/brands.service';
import { OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Events, MenuController, Platform, ToastController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { ProductService } from '../services/product/product.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { WalletService } from '../services/wallet/wallet.service';
import { ChatBotPage } from '../chat-bot/chat-bot.page';
import { ConfigService } from '../services/config/config.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ReferralService } from '../services/referral/referral.service';
import { encodeURL } from '../services/parseURL';
import { ProductSubscriptionsService } from '../services/product-subscriptions/product-subscriptions.service';
import { DesignStudioService } from '../services/design-studio/design-studio.service';
import { ConsoleService } from '../services/console/console.service';
export class SidemenuPage {
    constructor(storage, events, router, userService, productService, menuCtrl, socialSharing, platform, toastController, walletService, brandsService, servicesFeatureService, modalController, sharedService, configService, utilsService, referralService, adminSettingsService, designStudioService, consoleService, productSubscriptionsService) {
        this.storage = storage;
        this.events = events;
        this.router = router;
        this.userService = userService;
        this.productService = productService;
        this.menuCtrl = menuCtrl;
        this.socialSharing = socialSharing;
        this.platform = platform;
        this.toastController = toastController;
        this.walletService = walletService;
        this.brandsService = brandsService;
        this.servicesFeatureService = servicesFeatureService;
        this.modalController = modalController;
        this.sharedService = sharedService;
        this.configService = configService;
        this.utilsService = utilsService;
        this.referralService = referralService;
        this.adminSettingsService = adminSettingsService;
        this.designStudioService = designStudioService;
        this.consoleService = consoleService;
        this.productSubscriptionsService = productSubscriptionsService;
        this.showCategories = false;
        this.categoryPlusClicked = false;
        this.categoriesData = [];
        this.loginStatus = false;
        this.role = 'user';
        this.listOfSubcategories = {};
        this.appStoreUrl = '';
        this.playstoreUrl = '';
        this.isWalletActive = false;
        this.listOfSubcategoriesInView = {};
        this.showBrands = false;
        this.brands = [];
        this.brandPlusClicked = false;
        this.isServiceActive = false;
        this.showCategoriesLoader = false;
        this.showBrandsLoader = false;
        this.subscriptionFeature = false;
        this.RFQFeature = false;
        this.isReferralActive = false;
        this.isMembership = false;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.headerData = yield this.designStudioService.getSessionStorageHeaderData();
            this.consoleService.consoleLog('sidemenu:85 headerdata', this.headerData);
        });
    }
    menuOpen() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.storage.get('appUrls').then((urls) => {
                if (urls) {
                    if (this.platform.is('ios')) {
                        this.isAndroid = false;
                        this.appStoreUrl = urls.appStoreUrl;
                    }
                    else {
                        this.isAndroid = true;
                        this.playstoreUrl = urls.playstoreUrl;
                    }
                }
            });
            this.storage.get('storeInfo').then((data) => {
                this.storeName = data.storeName;
                this.storePhone = data.storePhone;
            });
            this.getUserData();
            setTimeout(() => {
                let userId = this.userService.getUserId();
                if (userId === '') {
                    this.loginStatus = false;
                }
                else {
                    this.loginStatus = true;
                }
            }, 100);
            this.isPriceReqFeature = this.configService.environment.priceReqFeature;
            const walletSettings = yield this.walletService.getWalletSettings('sidemenu');
            this.isWalletActive = walletSettings && walletSettings.active ? walletSettings.active : false;
            const serviceStatus = yield this.servicesFeatureService.getServicesActiveStatus('sidemenu');
            this.isServiceActive = serviceStatus && serviceStatus.isActive ? serviceStatus.isActive : false;
            if (this.configService.environment.subscriptionFeature) {
                const subSettings = yield this.productSubscriptionsService.getSettings('return');
                this.subscriptionFeature = subSettings && subSettings.isActive ? true : false;
            }
            this.RFQFeature = this.configService.environment.RFQFeature;
            const referralSettings = yield this.referralService.getReferralSettings('sidemenu');
            this.isReferralActive = referralSettings && referralSettings.active ? referralSettings.active : false;
            this.isMembership = this.configService.environment.membership;
            this.appointmentFeature = this.configService.environment.appointmentFeature;
            this.brands = (yield this.brandsService.getAllBrandsForSideMenu()) || [];
        });
    }
    menuClose() {
    }
    getUserData() {
        this.storage.get('userName').then((name) => {
            this.userName = name;
        });
        this.storage.get('userPic').then((url) => {
            this.userPic = url;
        });
        this.storage.get('userRole').then((role) => {
            if (!role) {
                this.role = 'user';
            }
            else {
                this.role = role;
            }
        });
    }
    goToPage(page) {
        this.menuCtrl.close();
        this.router.navigate([page]);
    }
    getAllCategories() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.categoryPlusClicked === false) {
                this.showCategories = true;
                this.categoriesData = yield this.productService.getAllCategoriesForSideMenu();
                //// console.log('categoriesData', this.categoriesData);
                this.categoryPlusClicked = true;
            }
            else {
                this.showCategories = false;
                this.categoryPlusClicked = false;
            }
        });
    }
    getSubCategories(cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.listOfSubcategories.hasOwnProperty(cid)) {
                let subcategories = [];
                subcategories = yield this.productService.getSubcategoriesInNewProduct(cid);
                this.listOfSubcategories[cid] = subcategories;
                //// console.log('listOfSubcategories', this.listOfSubcategories);
                this.listOfSubcategoriesInView[cid] = { active: true };
            }
            else {
                if (!this.listOfSubcategoriesInView[cid].active) {
                    this.listOfSubcategoriesInView[cid].active = true;
                }
                else {
                    this.listOfSubcategoriesInView[cid].active = false;
                }
            }
        });
    }
    urlSlug(value) {
        return encodeURL(value);
    }
    onClickCategory(category) {
        let cname = this.urlSlug(category.name);
        // this.sharedService.manageExclusiveCategories(category.isExclusive || false, category.id, 'parentCategory');
        if (category.isSubcategories) {
            this.router.navigate([`shop-subcategories/${cname}/${category.id}`]);
        }
        else {
            this.router.navigate([`shop/category/${cname}/${category.id}`]);
        }
    }
    onClickSubcategory(category, subcat) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // let exclusiveObj = await this.storage.get('exclusiveObj');
            // const isExclusive = exclusiveObj.categoryType === 'parentCategory' && exclusiveObj.exclusivity ? false : category.isExclusive;
            // this.sharedService.manageExclusiveCategories(isExclusive || false, category.id, 'subCategory');
            this.router.navigate(['/shop/category/', this.urlSlug(category.name), category.id, this.urlSlug(subcat.name), subcat.id]);
        });
    }
    getAllBrands() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.brandPlusClicked === false) {
                this.showBrandsLoader = true;
                if (!this.brands.length) {
                    this.brands = yield this.brandsService.getAllBrandsForSideMenu();
                }
                this.showBrands = true;
                this.brandPlusClicked = true;
                this.showBrandsLoader = false;
            }
            else {
                this.showBrands = false;
                this.brandPlusClicked = false;
            }
        });
    }
    onClickBrand(brand) {
        this.menuCtrl.close();
        let brandname = encodeURL(brand.name);
        this.router.navigate([`shop/brand/${brandname}/${brand.id}`]);
        //this.router.navigate(['shop'], navigationExtras);
    }
    logout() {
        this.menuCtrl.close();
        this.events.publish('auth:logout');
        this.events.publish('makeUnreadAdminMsgsZero');
    }
    openLoginModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.menuCtrl.close();
            this.utilsService.openLoginModal();
        });
    }
    openChatModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: ChatBotPage,
                cssClass: "chat-modal",
                showBackdrop: false,
                backdropDismiss: false
            });
            return yield modal.present();
        });
    }
    shareApp() {
        let appUrl = '';
        if (this.isAndroid) {
            appUrl = this.playstoreUrl;
        }
        else {
            appUrl = this.appStoreUrl;
        }
        if (appUrl) {
            this.socialSharing.share(this.storeName + ' link:', '', this.configService.environment.appSharingImg, appUrl).then(() => {
                //// console.log('app sharing works!');
            }).catch(() => {
                //// console.log('app sharing not works!');
            });
        }
        else {
            this.presentToast('There is some problem in sharing of app.');
        }
    }
    presentToast(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message: msg,
                duration: 2000
            });
            toast.present();
        });
    }
    //   toggleAccordian(event, index) {
    //     var element = event.target;
    //     console.log(element)
    //     element.classList.toggle("active");
    //     // if(this.data[index].isActive) {
    //     //   this.data[index].isActive = false;
    //     // } else {
    //     //   this.data[index].isActive = true;
    //     // }
    //     var panel = element.nextElementSibling;
    //     if (panel.style.maxHeight) {
    //       panel.style.maxHeight = null;
    //     } else {
    //       panel.style.maxHeight = panel.scrollHeight + "px";
    //     }
    // }
    toggleAccordian(type, i) {
        let item = document.getElementById(`${type}-item-${i}`);
        item.classList.toggle("active");
        let panel = document.getElementById(`${type}-menu-${i}`);
        panel.classList.toggle("active");
    }
}
