import { Component, OnInit } from '@angular/core';
import { Events, AlertController, LoadingController, NavController, NavParams, ToastController, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/services/config/config.service';
import { UpiManualPaymentPage } from '../pages/upi-manual-payment/upi-manual-payment.page';
import { LabelService } from 'src/app/services/label/label.service';
import { CartService } from '../services/cart/cart.service';
import { CustomPaymentOptionPage } from '../pages/custom-payment-option/custom-payment-option.page';
import { AdminSettingsService } from '../services/admin-settings/admin-settings.service';
import { StripePage } from '../pages/payment-ui/stripe/stripe.page';
import { PaypalPage } from '../pages/payment-ui/paypal/paypal.page';
import { OrderService } from '../services/order/order.service';
import { SharedService } from '../services/shared/shared.service';
import { CashfreePage } from '../pages/payment-ui/cashfree/cashfree.page';

@Component({
    selector: 'app-auto-confirm-payment',
    templateUrl: './auto-confirm-payment.page.html',
    styleUrls: ['./auto-confirm-payment.page.scss'],
})
export class AutoConfirmPaymentPage implements OnInit {
    orderData: any;
    loading: any;
    paytmActive: boolean = false;
    razorpayActive: boolean = false;
    razorpayId: any;
    showLoader: boolean = true;
    isCod: boolean = false;
    walletBalance: any;
    walletActive: boolean = true;
    minOrderAmntToUseWallet: number = 0;
    maxWalletAmntPerOrder: number = 1000;
    walletUsed: boolean = false;
    walletAmount: number = 0;
    envPaytmActive: boolean = false;
    currencyCode: any;
    taxType: string;
    cashbackAmount = 0;
    cashbackBalance = 0;
    upiManual = {
        active: false,
        upiId: '',
        qrCode: ''
    };
    SHARED_LABELS: any = {};
    AUTO_CONFIRM_PAYMENT_LABELS: any = {};
    isCodAvailableForCoupon: boolean = true;
    codPercent = 100;
    partialPayment = {
        status: false,
        cod: 0,
        online: {
            amount: 0,
            completed: false
        }
    }
    paymentType = 'full';
    customOption = {
        active: false,
        name: '',
        details: '',
        image: {
            url: ''
        },
        textMandatory: false,
        imageMandatory: false
    }
    stripeData: any = {
        active: false
    };
    paymentGateways = ['paypal', 'cashfree'];
    paypalObj: any = {
        active: false
    };
    cashfreeObj: any = {
        active: false
    };
    prodCashfree = false;

    extraCharge = {
        cash: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
        razorpay: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
        paytm: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
        stripe: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
        paypal: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
        cashfree: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0}
    }
    isGstApplicable: any;
    blockUsersCod = {
        active: false,
        users: []
    }

    constructor(private events: Events,
        private labelService: LabelService,
        private router: Router,
        private alertController: AlertController,
        private loadingController: LoadingController,
        private route: ActivatedRoute,
        public navParams: NavParams,
        private navCtrl: NavController,
        private modalController: ModalController,
        private configService: ConfigService,
        private toastController: ToastController,
        private cartService: CartService,
        private adminSettingsService: AdminSettingsService,
        private orderService: OrderService,
        private sharedService: SharedService) {
        this.orderData = navParams.get('orderData');
        this.isCodAvailableForCoupon = navParams.get('isCodAvailableForCoupon');
    }

    ngOnInit() {
    }
    ionViewDidEnter() {
        this.events.publish('admin-settings:getPaytmData');
        this.events.publish('admin-settings:getRazorPayData');
        this.events.publish('admin-settings:getPaymentInfoData');
        this.events.publish('wallet:getUserWalletDetails', this.orderData.userId);
        this.events.publish('wallet:getWalletSettings');
        this.initializeSubscriptions();
        this.currencyCode = this.configService.environment.currencyCode;
        this.taxType = this.configService.environment.taxType;
        this.prodCashfree = this.configService.environment.cashfree.production;
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.AUTO_CONFIRM_PAYMENT_LABELS = this.labelService.labels['AUTO_CONFIRM_PAYMENT'];

        this.getStripeDetails();

        this.getGateways();
    }

    ionViewDidLeave() {
        this.removeSubscription();
    }

    removeSubscription() {
        this.events.unsubscribe('user:setPaymentModeOfOrderByUserSuccessfully');
        this.events.unsubscribe('admin-settings:publishPaytmData');
        this.events.unsubscribe('admin-settings:publishRazorPayData');
        this.events.unsubscribe('admin-settings:publishPaymentInfoData');
        this.events.unsubscribe('order:ac_modeSetToCashSuccess');
        this.events.unsubscribe('wallet:publishUserWalletDetails');
        this.events.unsubscribe('wallet:publishWalletSettings');
        this.events.unsubscribe('order:ac_completePaymentWithWalletSuccess');
    }

    initializeSubscriptions() {
        this.events.subscribe('user:setPaymentModeOfOrderByUserSuccessfully', () => {
            this.loading.dismiss();
            this.presentAlert(`${this.AUTO_CONFIRM_PAYMENT_LABELS['payment_mode_set_msg']}`);
        });
        this.events.subscribe('admin-settings:publishPaytmData', (data) => {
            if (data) {
                this.paytmActive = false;
                this.extraCharge.paytm = 'extraChargePaytm' in data ? data.extraChargePaytm : this.getInitObjForExtraCharge();
            }
        });
        this.events.subscribe('admin-settings:publishRazorPayData', (data) => {
            if (data && data.active && data.id !== '') {
                this.razorpayActive = data.active;
                this.razorpayId = data.id;
                this.extraCharge.razorpay = 'extraChargeRazorpay' in data ? data.extraChargeRazorpay : this.getInitObjForExtraCharge();
            }
            this.showLoader = false;
        });
        this.events.subscribe('admin-settings:publishPaymentInfoData', (data) => {
            let isCod = typeof data.isCod !== 'undefined' ? data.isCod : true;
            let extraNoCodChecks = false;
            if (isCod) {
                for (const pdt of this.orderData.products) {
                    if ((pdt.hasOwnProperty('orderType') && pdt.orderType === 'membership') || ('isCod' in pdt && !pdt.isCod)) {
                        extraNoCodChecks = true;
                        break;
                    }
                }
            }
            if (extraNoCodChecks || !this.isCodAvailableForCoupon) {
                isCod = false;
            }
            this.isCod = isCod;
            this.upiManual = data.hasOwnProperty('upiManual') ? data.upiManual : this.upiManual;
            this.codPercent = 'codPercentage' in data ? data.codPercentage : 100;
            this.partialPayment.status = this.codPercent < 100 ? true : false;
            this.customOption = 'custom' in data ? data.custom : this.customOption;
            this.extraCharge.cash = 'extraCharge' in data ? data.extraCharge : this.getInitObjForExtraCharge();
            this.isGstApplicable = 'isGstApplicable' in data ? data.isGstApplicable : false;
            this.blockUsersCod = "blockUsersCod" in data ? data.blockUsersCod : this.blockUsersCod;
        });
        this.events.subscribe('order:ac_modeSetToCashSuccess', () => {
            this.loading.dismiss();
            this.presentAlert(`${this.AUTO_CONFIRM_PAYMENT_LABELS['order_placed_successfully']}`);
            this.modalDismiss();

        });
        this.events.subscribe('order:ac_completePaymentWithWalletSuccess', () => {
            this.loading.dismiss();

            this.presentAlert(`${this.AUTO_CONFIRM_PAYMENT_LABELS['order_placed_successfully']}`);
        });
        this.events.subscribe('wallet:publishUserWalletDetails', (data) => {
            if (data) {
                this.walletBalance = data.wallet ? data.wallet.balance : 0;
                this.cashbackBalance = data.wallet && data.wallet.cashback && data.wallet.cashback > 0 ? data.wallet.cashback : 0;
            }
        });
        this.events.subscribe('wallet:publishWalletSettings', (data) => {
            // // console.log('publishWalletSettings', data);
            if (!this.isEmptyObj(data)) {
                this.walletActive = typeof data.active !== 'undefined' ? data.active : true;
                this.minOrderAmntToUseWallet = data.minOrderAmnt ? data.minOrderAmnt : 0;
                this.maxWalletAmntPerOrder = data.maxWalletAmntPerOrder ? data.maxWalletAmntPerOrder : 1000;
            }
        });
    }
    getTotalItems() {
        return this.orderData.products.length;
    }

    modalDismiss() {
        this.modalController.dismiss();
    }

    async completePaymentWithWallet() {

        const alertRes = await this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg'])
        if(!alertRes) {
            return;
        }

        const valid = await this.checkOrderUpdation();
        if (!valid) {
            return;
        }

        await this.presentLoading(10000000);
        this.orderData['walletAmount'] = this.walletAmount;
        this.orderData['cashbackAmount'] = this.cashbackAmount;
        this.orderData.scheduledDate = this.orderData.scheduledDate ? (this.orderData.scheduledDate).toString() : '';
        this.events.publish('order:ac_completePaymentWithWallet', this.orderData);
    }

    async onClickPaymentMode(mode: string) {

        const alertRes = await this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg'])
        if(!alertRes) {
            return;
        }

        //? Block COD For Specific user Start (non partial payment)
        if (mode === 'cash') {
            if (this.blockUsersCod && this.blockUsersCod.active) {
                if (this.orderData.userId && this.blockUsersCod.users.length) {
                    for (const blockedUser of this.blockUsersCod.users) {
                        if (this.orderData.userId === blockedUser.id) {
                            await this.sharedService.presentAlert("You cannot use Cash On Delivery, Please choose another payment method");
                            return;
                        }
                    }
                }
            }
        }
        //? Block COD For Specific user End (non partial payment)

        const valid = await this.checkOrderUpdation();
        if (!valid) {
            return;
        }

        this.orderData.scheduledDate = this.orderData.scheduledDate ? (this.orderData.scheduledDate).toString() : '';
        this.checkPartialPayment();

        this.orderData['walletAmount'] = this.walletAmount;
        this.orderData['cashbackAmount'] = this.cashbackAmount;

        if (mode === 'cash') {
            await this.presentLoading(100000);
            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.cash);
            this.events.publish('order:ac_payWithCash', this.orderData);
        }
        if (mode === 'card' || mode === 'wallet' || mode === 'upi' || mode === 'netbanking') {
            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.razorpay);
            this.events.publish('order:ac_payWithRazorPay', this.orderData, this.razorpayId, mode);
            this.modalDismiss();
        }
        if (mode === 'paytm') {
            this.modalDismiss();
            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.paytm);
            const navigationExtras: NavigationExtras = {
                state: {
                    orderData: this.orderData
                }
            };
            this.router.navigate(['paytm-initiate-txn'], navigationExtras);
        }

        if (mode === 'upiManual') {
            this.modalDismiss();
            this.presentUPIModal(this.orderData, this.upiManual)
        }

        if (mode === 'custom') {
            this.modalDismiss();
            this.presentCustomOptionModal();
        }

        if (mode === 'stripe') {
            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.stripe);
            this.modalDismiss();
            this.presentStripeModal();
        }

        if (mode === 'paypal') {
            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.paypal);
            this.modalDismiss();
            this.presentPaypalModal();
        }

        if (mode === 'cashfree') {
            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.cashfree);
            this.modalDismiss();
            this.presentCashfreeModal();
            // this.payWithCashfree();
        }

    }

    getExtraChargeAmount(extraChargeObj) {
        let extraCharge = 0;
        extraChargeObj = this.paymentType === 'partial' ? this.extraCharge.cash : extraChargeObj;
        if(Object.keys('extraChargeObj').length && extraChargeObj.charge) {
            if(extraChargeObj.type === 'flat') {
                extraCharge = extraChargeObj.charge;
            } else {
                extraCharge = (this.orderData.totalAmountToPaid - (this.orderData.walletAmount + this.orderData.cashbackAmount)) * (extraChargeObj.charge / 100);
                if(extraCharge > extraChargeObj.maxCharge) {
                    extraCharge = extraChargeObj.maxCharge;
                }
            }
            this.orderData.totalAmountToPaid += extraCharge; 
            this.calcGst(extraCharge);
        }
        return {charge: extraCharge, name: extraChargeObj.chargeName || ''};
    }

    calcGst(extraCharge) {
        let allGst = [];
        let gstOnExtraCharge = 0;
        this.orderData.products.map((p) => {
            if (p.gst && this.isGstApplicable) {
                allGst.push(p.gst);}
        });
        if (allGst.length) {
            let minGst = 0;
            minGst = Math.min(...allGst);
            gstOnExtraCharge = (extraCharge - (extraCharge / (1 + (minGst / 100))));
            this.orderData.defaultGst += gstOnExtraCharge;
            this.orderData.deliveryGstObj.extraChargeGst = (this.orderData.deliveryGstObj.extraChargeGst || 0) + gstOnExtraCharge;
        }
    }

    async checkOrderUpdation() {
        await this.presentLoading(100000);
        console.log('this.orderData.products', this.orderData.products);
        const isProductsUpdated = (await this.cartService.compareCartWithUpdatedCart(this.orderData.products, 'order-summary')).cartUpdated;
        console.log('isProductsUpdated', isProductsUpdated);
        let isQtyAvailable = true;
        if (!isProductsUpdated) {
            isQtyAvailable = await this.cartService.inventoryManagement({products: this.orderData.products, orderId: this.orderData.id});
            console.log('isQtyAvailable', isQtyAvailable);
            if (isQtyAvailable) {
                this.loading.dismiss();
                return true;
            }
        }
        if (isProductsUpdated || !isQtyAvailable) {
            this.loading.dismiss();
            this.presentAlert(this.SHARED_LABELS['cart_updated_alert'], 'user-cart');
            return false;
        }
    }

    async presentUPIModal(order, upiManual) {
        const res = await this.orderService.ac_payWithUPIManual(order)
        const modal = await this.modalController.create({
            component: UpiManualPaymentPage,
            backdropDismiss: false,
            cssClass: 'custom-modal',
            componentProps: {
                order: res.order,
                upiManual: upiManual
            }
        });
        modal.onDidDismiss().then(res => {
            if(res && res.data && res.data.closed) {
                this.navCtrl.navigateRoot(['user-order-history']);
            };
        });
        await modal.present();

    }

    async presentCustomOptionModal() {
        const modal = await this.modalController.create({
            component: CustomPaymentOptionPage,
            backdropDismiss: false,
            cssClass: 'custom-modal',
            componentProps: {
                order: this.orderData,
                customOption: this.customOption
            }
        });
        modal.onDidDismiss().then(res => {
            if(res && res.data && res.data.closed) {
                this.navCtrl.navigateRoot(['user-order-history']);
            };
        });
        await modal.present();

    }

    isEmptyObj(object) {
        for (var key in object) {
            if (object.hasOwnProperty(key))
                return false;
        }
        return true;
    }


    onClickUseWallet() {
        if (this.walletUsed) {
            this.walletUsed = false;
            this.walletAmount = 0;
            this.cashbackAmount = 0;
        } else {
            this.getUsableWalletAmnt();
            this.walletUsed = true;
        }
    }
    getUsableWalletAmnt() {
        let walletUsed = 0;
        let cashbackUsed = 0;
        if (this.orderData.totalAmountToPaid < this.minOrderAmntToUseWallet) {
            walletUsed = this.orderData.totalAmountToPaid >= this.walletBalance ? this.walletBalance : this.orderData.totalAmountToPaid;
            this.walletAmount = walletUsed;
        } else {
            cashbackUsed = this.orderData.totalAmountToPaid >= this.cashbackBalance ? this.cashbackBalance : this.orderData.totalAmountToPaid;
            cashbackUsed = cashbackUsed > this.maxWalletAmntPerOrder ? this.maxWalletAmntPerOrder : cashbackUsed;
            this.cashbackAmount = cashbackUsed;
            if (this.orderData.totalAmountToPaid - this.cashbackAmount > 0) {
                walletUsed = (this.orderData.totalAmountToPaid - this.cashbackAmount) >= this.walletBalance ? this.walletBalance : (this.orderData.totalAmountToPaid - this.cashbackAmount);
                this.walletAmount = walletUsed;
            }
        }
    }

    checkPartialPayment() {
        const partial = { ...this.partialPayment };
        if (this.paymentType === 'partial') {
            partial.status = true;
        } else {
            partial.status = false;
        }
        this.orderData['partialPayment'] = partial;
    }

    setPaymentType(type: string) {
        this.paymentType = type;

        if (type === 'partial') {
            this.partialPayment['cod'] = parseFloat((this.getFinalAmount() * (this.codPercent / 100)).toFixed(2));
            this.partialPayment['online']['amount'] = parseFloat((this.getFinalAmount() - this.partialPayment['cod']).toFixed(2));
        }
    }

    getFinalAmount() {
        return this.orderData.totalAmountToPaid - (this.walletAmount + this.cashbackAmount);
    }

    async presentToast(msg: string) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000,
        });
        toast.present();
    }
    async presentAlert(msg: string, page: string = 'order-successful') {
        const alert = await this.alertController.create({
            message: msg,
            backdropDismiss: false,
            buttons: [{
                text: this.SHARED_LABELS['ok'],
                handler: () => {
                    if (this.modalController) {
                        this.modalDismiss();
                    }
                    this.navCtrl.navigateRoot([page]);
                }
            }]
        });
        await alert.present();
    }
    async presentLoading(duration?) {
        this.loading = await this.loadingController.create({
            message: this.SHARED_LABELS['please_wait'],
            duration: duration ? duration : 5000
        });
        await this.loading.present();
    }

    async getStripeDetails() {
        this.stripeData = await this.adminSettingsService.getStripeData();
        this.extraCharge.stripe = 'extraChargeStripe' in this.stripeData ? this.stripeData.extraChargeStripe : this.getInitObjForExtraCharge();
    }

    async presentStripeModal() {
        const modal = await this.modalController.create({
            component: StripePage,
            backdropDismiss: false,
            cssClass: 'custom-modal',
            componentProps: {
                orderData: this.orderData,
                stripeData: this.stripeData,
                autoConfirm: true
            }
        });
        modal.onDidDismiss().then(res => {
            if(res && res.data && res.data.closed) {
                this.navCtrl.navigateRoot(['user-order-history']);
            };
        });
        await modal.present();
    }

    async presentPaypalModal() {
        const modal = await this.modalController.create({
            component: PaypalPage,
            backdropDismiss: false,
            cssClass: 'custom-modal',
            componentProps: {
                orderData: this.orderData,
                autoConfirm: true,
                paypalObj: this.paypalObj
            }
        });
        modal.onDidDismiss().then(res => {
            if(res && res.data && res.data.closed) {
                this.navCtrl.navigateRoot(['user-order-history']);
            };
        });
        await modal.present();
    }

    async getGateways() {
        for (const paymentGateway of this.paymentGateways) {
            let paymentGatewayData = await this.adminSettingsService.getPaymentGateway(paymentGateway);
            if (paymentGatewayData && paymentGatewayData.active) {
                if (paymentGateway == 'paypal') {
                    this.paypalObj = paymentGatewayData;
                    this.extraCharge.paypal = 'extraChargePaypal' in this.paypalObj ? this.paypalObj.extraChargePaypal : this.getInitObjForExtraCharge();
                }
                if (paymentGateway == 'cashfree') {
                    this.cashfreeObj = paymentGatewayData;
                    this.extraCharge.cashfree = 'extraChargeCashfree' in this.cashfreeObj ? this.cashfreeObj.extraChargeCashfree : this.getInitObjForExtraCharge();
                }
            }
        }
    }

    // async payWithCashfree(){
    //     console.log('this.orderData:',this.orderData );
    //     await this.orderService.createOrderCashfree(this.orderData, true);
    // }
    
  async presentCashfreeModal() {
    const modal = await this.modalController.create({
        component: CashfreePage,
        cssClass:'custom-modal',
        componentProps: {
          orderData: this.orderData,
          autoConfirm: true
        }
    });
    await modal.present();
    }

    getInitObjForExtraCharge() {
        return {charge: 0, type: 'flat', chargeName: '', maxCharge: 0};
    }

}
