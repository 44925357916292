import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { PaymentModulePage } from 'src/app/pages/payment-module/payment-module.page';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase';
import { ConfigService } from '../config/config.service';
import { UserService } from '../user/user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { LabelService } from '../label/label.service';
import { SharedNewService } from '../shared-new/shared-new.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { first } from 'rxjs/operators';

declare var Razorpay;
declare var paytm: any;

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private modalController: ModalController,
    private storage: Storage,
    private configService: ConfigService,
    private userService: UserService,
    private platform: Platform,
    private afs: AngularFirestore,
    private labelService: LabelService,
    private sharedNewService: SharedNewService,
    private fbStorage: AngularFireStorage,) { }

  async openPaymentModal(data) {
    const modal = await this.modalController.create({
      component: PaymentModulePage,
	  cssClass: 'custom-modal',
      backdropDismiss: false,
      componentProps: { paymentData: data },
    });

    await modal.present();
  }

  getPartialPaymentObj(data) {
    return {
      cod: data.order.partialPayment.cod,
      online: data.order.amount,
      status: 'pending'
    }
  }

  async razorpayMethod(data) {
    return new Promise<{ status: string, response: any }>(async (resolve, reject) => {
      data.order.amount = this.getPayableAmount(data.order);
      const paymentRes: any = {
        completed: false,
        mode: 'razorpay',
        status: 'pending',
        details: null,
        walletAmount: data.order.walletAmount,
        cashbackAmount: data.order.cashbackAmount
      }
      if(data.order.partialPayment.status) {
        paymentRes.partial = this.getPartialPaymentObj(data);
      }
      let createOrderInRazorpay = firebase.functions().httpsCallable('payments-razorpay_createOrder');
      const createOrderRes = await createOrderInRazorpay({ amount: Math.round(data.order.amount * 100), orderDocId: data.order.id, collection: data.order.collection });
      const razorpayOrderId = createOrderRes.data && createOrderRes.data.orderId ? createOrderRes.data.orderId : '';
      console.log('razorpayOrderId', razorpayOrderId);
      const historyObj: any = {
        orderId: data.order.id,
        orderAmount: data.order.amount,
        userId: data.order.userId,
        type: data.order.collection,
        mode: 'razorpay',
        walletAmount: data.order.walletAmount,
        cashbackAmount: data.order.cashbackAmount
      }
      if (razorpayOrderId) {
        const storeInfo = await this.storage.get('storeInfo');
        const options = {
          order_id: razorpayOrderId,
          description: this.configService.environment.razorpay.description,
          currency: this.configService.environment.razorpay.currency,
          key: data.paymentCreds.key,
          amount: Math.round(data.order.amount * 100),
          name: storeInfo.storeName ? storeInfo.storeName : '',
          image: this.configService.environment.razorpay.image,
          prefill: {
            method: data.method,
            contact: this.userService.getPhoneNo(),
            name: this.userService.getUserName(),
            email: this.userService.getUserEmail() || 'xyz@gmail.com',
          },
          theme: this.configService.environment.razorpay.theme,
          modal: {
            ondismiss: async () => {
              resolve({ status: 'failed', response: paymentRes });
            }
          },
          handler: async (handlerResponse) => {
            console.log('handlerResponse', handlerResponse);
            let verifySignature = firebase.functions().httpsCallable('payments-razorpay_verifySignature');
            const apiBody = {
              razorpay_payment_id: handlerResponse.razorpay_payment_id,
              razorpay_order_id: handlerResponse.razorpay_order_id,
              razorpay_signature: handlerResponse.razorpay_signature,
            }

            const txnRes = { paymentId: apiBody.razorpay_payment_id };
            historyObj.details = txnRes;
            await this.createPaymentHistory(historyObj);
            paymentRes.details = txnRes;
            const verifySignatureRes = await verifySignature(apiBody)
            console.log('verifySignatureRes', verifySignatureRes);
            if (verifySignatureRes.data.signatureIsValid) {
              paymentRes.completed = true;
              paymentRes.status = 'completed';
              resolve({ status: 'success', response: paymentRes });
            } else {
              resolve({ status: 'failed', response: paymentRes });
            }
          }
        };
        var razorpay = new Razorpay(options);
        razorpay.open();
        razorpay.on('payment.failed', async (response) => {
          console.log('failure response', response);
          const paymentId = response.error && response.error.metadata && response.error.metadata.payment_id ? response.error.metadata.payment_id : '';
          if (paymentId) {
            const txnRes = { paymentId: paymentId };
            historyObj.details = txnRes;
            await this.createPaymentHistory(historyObj);
            paymentRes.details = txnRes;
          }
          resolve({ status: 'failed', response: paymentRes });
        });
      } else {
        resolve({ status: 'failed', response: paymentRes });
      }
    });
  }

  async cashMethod(data) {
    return new Promise<{ status: string, response: any }>(async (resolve, reject) => {
      data.order.amount = this.getPayableAmount(data.order);
      const paymentRes: any = {
        completed: false,
        mode: 'cash',
        details: {
          amount: data.order.amount
        }
      };
      if(data.order.walletAmount || data.order.cashbackAmount) {
        const historyObj = {
          orderId: data.order.id,
          orderAmount: data.order.amount,
          details: {
            amount: data.order.amount
          },
          userId: data.order.userId,
          type: data.order.collection,
          mode: 'cash',
          walletAmount: data.order.walletAmount,
          cashbackAmount: data.order.cashbackAmount
        }
        await this.createPaymentHistory(historyObj);
      }
      resolve({ status: 'success', response: paymentRes });
    });
  }

  async paytmMethod(data) {
    return new Promise(async (resolve, reject) => {
      const paymentRes: any = {
        completed: false,
        mode: 'paytm',
        status: 'pending',
        details: null
      }
      if(data.order.partialPayment.status) {
        paymentRes.partial = this.getPartialPaymentObj(data);
      }
      try {
        const mobileNo = this.userService.getPhoneNo().slice(3);
        data.order.amount = this.getPayableAmount(data.order);
        let getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
        getCheckSum({
          orderId: data.order.id.toString(),
          customerId: data.order.userId,
          phoneNo: mobileNo,
          txnAmount: data.order.amount.toString(),
        }).then((result) => {
          console.log('checksum:', result.data.checksum);

          const paytmParams: any = {
            MID: result.data.mid,
            ORDER_ID: data.order.id.toString(),
            CUST_ID: data.order.userId,
            CHANNEL_ID: this.configService.environment.paytm.CHANNEL_ID,
            TXN_AMOUNT: data.order.amount.toString(),
            WEBSITE: this.configService.environment.paytm.WEBSITE,
            CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + data.order.id.toString(),
            INDUSTRY_TYPE_ID: this.configService.environment.paytm.INDUSTRY_TYPE_ID,
            MOBILE_NO: mobileNo,
            CHECKSUMHASH: result.data.checksum,
            ENVIRONMENT: this.configService.environment.paytm.ENVIRONMENT
          };
          let successCallback = async (response: any) => {
            if (response.STATUS == "TXN_SUCCESS") {
              const historyObj = {
                orderId: data.order.id,
                orderAmount: data.order.amount,
                details: response,
                userId: data.order.userId,
                type: data.order.collection,
                mode: 'paytm',
                walletAmount: data.order.walletAmount,
                cashbackAmount: data.order.cashbackAmount
              }
              await this.createPaymentHistory(historyObj);
              paymentRes.details = response;
			  paymentRes.completed = true;
              paymentRes.status = 'completed';
              resolve({ status: 'success', response: paymentRes });
            } else {
              resolve({ status: 'failed', response: paymentRes });
            }
          };

          let failureCallback = async (error: any) => {
            resolve({ status: 'failed', response: paymentRes });
          };

          paytm.startPayment(paytmParams, successCallback, failureCallback);

        }).catch(function (error) {
          resolve({ status: 'failed', response: paymentRes });
        });
      } catch (error) {
        resolve({ status: 'failed', response: paymentRes });
      }
    });
  }

  async walletMethod(data) {
    return new Promise(async (resolve, reject) => {
      const paymentRes: any = {
        completed: true,
        mode: 'wallet',
        status: 'completed'
      };
      const historyObj = {
        orderId: data.order.id,
        orderAmount: data.order.amount,
        details: {
          amount: data.order.amount
        },
        userId: data.order.userId,
        type: data.order.collection,
        mode: 'wallet',
        walletAmount: data.order.walletAmount,
        cashbackAmount: data.order.cashbackAmount
      }
      await this.createPaymentHistory(historyObj);
      resolve({ status: 'success', response: paymentRes });
    });
  }

  async upiManaulMethod(data, paymentImg) {
    return new Promise(async (resolve, reject) => {
      const imgRef: any = this.fbStorage.ref(`upiManualPayment/${data.order.id}/image/` + new Date().getTime().toString() + '.png');
      await imgRef.putString(paymentImg, 'data_url');
      const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
      const paymentRes = {
          completed: true,
          mode: 'upiManual',
          screenshot: downloadURL,
          status: 'completed'
      }
      if(data.order.walletAmount || data.order.cashbackAmount) {
        const historyObj = {
          orderId: data.order.id,
          orderAmount: data.order.amount,
          details: {
            amount: data.order.amount
          },
          userId: data.order.userId,
          type: data.order.collection,
          mode: 'wallet',
          walletAmount: data.order.walletAmount,
          cashbackAmount: data.order.cashbackAmount
        }
        await this.createPaymentHistory(historyObj);
      }
      resolve({ status: 'success', response: paymentRes });
    });
  }

  async customOptionMethod(data, userResponse) {
    return new Promise(async (resolve, reject) => {
      let downloadURL = '';
      if (userResponse.image) {
          const imgRef: any = this.fbStorage.ref(`customPayment/${data.order.id}/image/` + new Date().getTime().toString() + '.png');
          await imgRef.putString(userResponse.image, 'data_url');
          downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
      }
      const paymentRes = {
          completed: true,
          mode: 'custom',
          optionName: userResponse.optionName,
          screenshot: downloadURL,
          textDetails: userResponse.textDetails,
          status: 'completed'
      }
      if(data.order.walletAmount || data.order.cashbackAmount) {
        const historyObj = {
          orderId: data.order.id,
          orderAmount: data.order.amount,
          details: {
            amount: data.order.amount
          },
          userId: data.order.userId,
          type: data.order.collection,
          mode: 'wallet',
          walletAmount: data.order.walletAmount,
          cashbackAmount: data.order.cashbackAmount
        };
        await this.createPaymentHistory(historyObj);
      }
      resolve({ status: 'success', response: paymentRes });
    });
  }

  async handlePaymentRes(paymentRes, paymentData) {
    await this.afs.collection(paymentData.order.collection).doc(paymentData.order.id).update({
      payment: {...paymentRes.response},
	  status: paymentRes.status === 'success' ? 'accepted' : 'pending'
		// totalGst: firebase.firestore.FieldValue.increment(paymentRes.response.extraChargeOnPayment.gst)
    });
    const alertMessage = paymentRes.status === 'success' ? this.labelService.labels['PAYMENT_SERVICE']['payment_success_msg'] :
    paymentRes.mode === 'razorpay' ? this.labelService.labels['PAYMENT_SERVICE']['razorpay_payment_failed_msg'] :
    this.labelService.labels['PAYMENT_SERVICE']['payment_failed_msg'];
    this.sharedNewService.presentAlert({msg: alertMessage, page: paymentData.successRoute, backdropDismiss : false});
    if(this.modalController) {
      this.modalController.dismiss();
    }
  }

  async createPaymentHistory(historyObj) {
    const paymentHistoryObj = {
      paidAt: new Date(),
      status: 'successful',
      ...historyObj
    }
    if (historyObj.mode === 'razorpay') {
      paymentHistoryObj['status'] = 'pending';
    }
    await this.afs.collection('payment').doc('history').collection('payments').add(paymentHistoryObj);
  }

  getPayableAmount(order) {
    console.log('order', order);
    return order.partialPayment.status ? order.partialPayment.online.amount : (order.amount - (order.walletAmount + order.cashbackAmount));
  }

}