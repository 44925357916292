/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./place-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/forms";
import * as i6 from "./place-modal.page";
import * as i7 from "../../services/vendor/vendor.service";
var styles_PlaceModalPage = [i0.styles];
var RenderType_PlaceModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_PlaceModalPage, data: {} });
export { RenderType_PlaceModalPage as RenderType_PlaceModalPage };
export function View_PlaceModalPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-header", [["no-border", ""]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "ion-toolbar", [], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(6, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 18, "ion-content", [["class", "ion-padding"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(9, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["src", "../../../assets/img/shop-logo-center.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, 0, 14, "form", [["class", "form-horizontal m-t-36"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i5.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(14, 4210688, [["SearchPlacesForm", 4]], 0, i5.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.NgForm]), i1.ɵdid(16, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 9, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "label", [["for", "txtSearchPlaces"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Search for area, street name..."])), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 5, "input", [["class", "form-control form-input"], ["id", "txtSearchPlaces"], ["name", "txtSearchPlaces"], ["placeholder", "Enter your delivery location"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 22).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 22)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 22)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.shippingAddress = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(24, 671744, [["txtSearchPlaces", 4]], 0, i5.NgModel, [[2, i5.ControlContainer], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(26, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(27, 0, null, null, 5, "ion-footer", [["no-border", ""]], null, null, null, i2.View_IonFooter_0, i2.RenderType_IonFooter)), i1.ɵdid(28, 49152, null, 0, i3.IonFooter, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(29, 0, null, 0, 3, "ion-button", [["class", "btn-2"], ["color", "success"], ["expand", "full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveLocation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(30, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], disabled: [1, "disabled"], expand: [2, "expand"] }, null), (_l()(), i1.ɵted(31, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_15 = "txtSearchPlaces"; var currVal_16 = _co.shippingAddress; _ck(_v, 24, 0, currVal_15, currVal_16); var currVal_17 = "success"; var currVal_18 = _co.disableApply(); var currVal_19 = "full"; _ck(_v, 30, 0, currVal_17, currVal_18, currVal_19); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("PLACE_MODAL.where_want_delivery")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 16).ngClassValid; var currVal_6 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = i1.ɵnov(_v, 26).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 26).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 26).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 26).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 26).ngClassValid; var currVal_13 = i1.ɵnov(_v, 26).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 26).ngClassPending; _ck(_v, 21, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_20 = i1.ɵunv(_v, 31, 0, i1.ɵnov(_v, 32).transform("PLACE_MODAL.save_btn")); _ck(_v, 31, 0, currVal_20); }); }
export function View_PlaceModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-place-modal", [], null, null, null, View_PlaceModalPage_0, RenderType_PlaceModalPage)), i1.ɵdid(1, 114688, null, 0, i6.PlaceModalPage, [i7.VendorService, i3.ModalController, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlaceModalPageNgFactory = i1.ɵccf("app-place-modal", i6.PlaceModalPage, View_PlaceModalPage_Host_0, {}, {}, []);
export { PlaceModalPageNgFactory as PlaceModalPageNgFactory };
