import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.page.html',
  styleUrls: ['./splash-screen.page.scss'],
})
export class SplashScreenPage implements OnInit {

  constructor(private modalController: ModalController) { }
  @Input() data: any;
  ngOnInit() {
  }

  ionViewDidEnter() {

    console.log('splash data', this.data)
    setTimeout(async () => {
      await this.modalController.dismiss();
    }, parseInt(this.data.timeout) * 1000);


  }

}
