import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { AngularFireStorage } from '@angular/fire/storage';
import { map, first } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
export class DeliveryService {
    constructor(afs, events, fbStorage, storage) {
        this.afs = afs;
        this.events = events;
        this.fbStorage = fbStorage;
        this.storage = storage;
    }
    initializeSubscriptions() {
        this.events.subscribe('delivery:getAllOrdersOfDeliveryAgent', (uid) => {
            this.getAllOrdersOfDeliveryAgent(uid);
        });
        this.events.subscribe('delivery:updateLatLongOfDeliveryAgent', (lat, lng) => {
            this.updateLatLongOfDeliveryAgent(lat, lng);
        });
        this.events.subscribe('delivery:updateDeliveryStatus', (oid, status) => {
            this.updateDeliveryStatus(oid, status);
        });
        this.events.subscribe('delivery:getLatestLatLngOfDeliveryAgent', (agentId) => {
            this.getLatestLatLngOfDeliveryAgent(agentId);
        });
        this.events.subscribe('delivery:startDelivery', (orderId, address) => {
            this.startDelivery(orderId, address);
        });
    }
    startDelivery(orderId, address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (!address.lat) {
                    let getLatLng = firebase.functions().httpsCallable('location-getLatLng');
                    getLatLng({
                        deliveryAddress: address.address,
                        orderId: orderId
                    }).then((res) => {
                        //// console.log('response form server...', res);
                        const status = res.data.status;
                        if (status === 'OK') {
                            this.updateDeliveryStatus(orderId, 'inProgress', res.data.results[0].geometry.location);
                            this.events.publish('delivery:startDeliverySuccess', orderId, res.data.results[0].geometry.location);
                        }
                        else if (status === 'ZERO_RESULTS') {
                            this.events.publish('delivery:startDeliveryUnsuccessful', "Delivery Address is not existed!");
                        }
                        else {
                            this.events.publish('delivery:startDeliveryUnsuccessful', "There is some problem in fetching address location. Please try again later.");
                        }
                    }).catch((error) => {
                        //// console.log(error);
                    });
                }
                else {
                    this.updateDeliveryStatus(orderId, 'inProgress', { lat: address.lat, lng: address.lng });
                    this.events.publish('delivery:startDeliverySuccess', orderId, { lat: address.lat, lng: address.lng });
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getAllOrdersOfDeliveryAgent(uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const orderRef = this.afs.collection('orders', ref => ref
                    .orderBy('createdAt', 'desc')
                    .where('deliveryAgentId', '==', uid));
                const orderData = yield orderRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                })));
                orderData.subscribe((orders) => {
                    if (!orders.length) {
                        this.events.publish('delivery:noOrdersOfDeliveryAgent');
                        return false;
                    }
                    else {
                        //// console.log('getAllOrdersOfDeliveryAgent', orders);
                        this.events.publish('delivery:publishAllOrdersOfDeliveryAgent', orders);
                    }
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    updateDeliveryStatus(oid, status, location) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', oid));
                const orderData = yield orderRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                if (status === 'inProgress') {
                    yield this.afs.collection('orders').doc(orderData[0].id).update({ deliveryStatus: status, status: 'Dispatched' });
                    const deliverStartedMsg = {
                        author: 'admin',
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        isRead: true,
                        orderId: orderData[0].orderId,
                        published: true,
                        status: 'deliveryStarted',
                        type: 'order',
                        agentId: orderData[0].deliveryAgentId,
                        deliveryLatLng: location
                    };
                    this.events.publish('chat:sendMsg', deliverStartedMsg, orderData[0].userId);
                }
                if (status === 'delivered') {
                    yield this.afs.collection('orders').doc(orderData[0].id).update({ deliveryStatus: status, status: 'Delivered' });
                    this.events.publish('delivery:finishedNavigationSuccess');
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    updateLatLongOfDeliveryAgent(lat, lng) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.storage.get('uid').then((uid) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    yield this.afs.collection('users').doc(uid).update({
                        latitude: lat,
                        longitude: lng
                    });
                }
                catch (error) {
                    console.dir(error);
                }
            }));
        });
    }
    getLatestLatLngOfDeliveryAgent(agentId) {
        try {
            const agentRef = this.afs.collection('users').doc(agentId);
            const agentSnap = agentRef.valueChanges();
            agentSnap.subscribe((res) => {
                //// console.log('res', res);
                this.events.publish('delivery:publishLatestLatLngOfDeliveryAgent', res.latitude, res.longitude);
            });
        }
        catch (error) {
            console.dir(error);
        }
    }
    getLatLngFromAddress(address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let latLongObj = firebase.functions().httpsCallable('location-getLatLngFromAddress');
                latLongObj(address).then((res) => {
                    resolve(res.data);
                });
            }));
        });
    }
    getAddressFromLatLng(latLngObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let Address = firebase.functions().httpsCallable('location-getAddressFromLatLng');
                Address(latLngObj).then((res) => {
                    resolve(res.data);
                });
            }));
        });
    }
}
DeliveryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeliveryService_Factory() { return new DeliveryService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage)); }, token: DeliveryService, providedIn: "root" });
