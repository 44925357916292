import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { AppointmentService } from 'src/app/services/appointment/appointment.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
    selector: 'app-appointment-schedules',
    templateUrl: './appointment-schedules.page.html',
    styleUrls: ['./appointment-schedules.page.scss'],
})
export class AppointmentSchedulesPage implements OnInit {
    schedules = [];
    maxDays = 0;
    scheduleSlots = [];
    selectedTime: any = {};
    item = {};
    booked = false;
    vendor: any;
    constructor(private userService: UserService,
        private appointmentService: AppointmentService,
        private sharedService: SharedService,
        private labelService: LabelService,
        private modalController: ModalController,
        private router: Router) { }

    ngOnInit() {
      console.log('schedules', this.schedules);
      console.log('maxDays', this.maxDays);
      console.log('item', this.item);
        this.getSchedules();
    }

    getSchedules() {
        for (let index = 0; index < this.maxDays; index++) {
            let dayToCheck = moment().add(index, 'days');
            const schedule = this.schedules.filter(s => s.day === dayToCheck.format('dddd'));
            if (schedule.length) {
                if (schedule[0].active && schedule[0].schedule.length) {
                    this.scheduleSlots.push({
                            date: dayToCheck.toDate(),
                            schedules: schedule[0].schedule,
                            day: schedule[0].day,
                            active: false
                        });
                }
            }
        }
        this.scheduleSlots[0].active = true;
    }

    selectDate(i: number) {
        this.scheduleSlots[i].active = true;
        this.scheduleSlots.forEach((slot, index) => {
            if (index !== i) {
                slot.active = false;
            }
        });
    }

    getActiveTimeSchedules() {
        return this.scheduleSlots.filter(s => s.active === true)[0].schedules;
    }

    setTimeSlot(event) {
        this.selectedTime = event.target.value;
    }

    async bookAppointment() {
        await this.sharedService.presentLoading();
        const scheduleSlot = this.scheduleSlots.filter(s => s.active === true)[0];
        const data = {
            item: this.item,
            schedule: {
                date: moment(scheduleSlot.date).format('DD-MM-YYYY'),
                slot: {
                    start: this.selectedTime.start,
                    end: this.selectedTime.end
                },
                day: scheduleSlot.day
            },
            vendor: this.vendor,
            user: {
                id: this.userService.getUserId(),
                name: this.userService.getUserName()
            },
            status: 'accepted'
        }
        const res = await this.appointmentService.bookAppointment(data, this.selectedTime.slotLimit);
        this.sharedService.loading.dismiss();
        if (res === 'added') {
            this.sharedService.presentAlert(this.labelService.labels['APPOINTMENT_SCHEDULE']['appointment_booked']);
            this.booked = true;
            this.closeModal();
            //this.router.navigate(['/']);
        } else if(res === 'not_available') {
            this.sharedService.presentAlert(this.labelService.labels['APPOINTMENT_SCHEDULE']['slot_not_available']);
        } else {
            this.sharedService.presentAlert(this.labelService.labels['SHARED']['some_issue_msg']);
        }
    }
    
    closeModal() {
      this.modalController.dismiss({booked: this.booked});
    }

    getDate(date) {
      return moment(date).format('DD MMM');
    }

    isBookNowDisabled() {
      return !Object.keys(this.selectedTime).length;
    }

}
