import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
export class SplashScreenPage {
    constructor(modalController) {
        this.modalController = modalController;
    }
    ngOnInit() {
    }
    ionViewDidEnter() {
        console.log('splash data', this.data);
        setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.modalController.dismiss();
        }), parseInt(this.data.timeout) * 1000);
    }
}
