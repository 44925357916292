import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import * as firebase from 'firebase';
import { AngularFireStorage } from '@angular/fire/storage';
import { first, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
export class BannersService {
    constructor(events, afs, fbStorage) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
    }
    initializeSubscriptions() {
        this.events.subscribe('banners:uploadBanner', (index, base64Image, size) => {
            this.uploadBanner(index, base64Image, size);
        });
        this.events.subscribe('banners:getBanners', () => {
            this.getBanners();
        });
        this.events.subscribe('banners:getSubscribedBanners', () => {
            this.getSubscribedBanners();
        });
        this.events.subscribe('banners:getBannersActiveStatus', () => {
            this.getBannersActiveStatus();
        });
        this.events.subscribe('banners:changeBannersStatus', (status) => {
            this.changeBannersStatus(status);
        });
        this.events.subscribe('banners:removeBanner', (index) => {
            this.removeBanner(index);
        });
    }
    uploadBanner(index, base64Image, size) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('features').doc('banners').collection('images').doc(`image${index}`).set({
                    uploadedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    size: size
                });
                const imgRef = this.fbStorage.ref(`banners/images/image${index}/image${index}.png`);
                yield imgRef.putString(base64Image, 'data_url');
                this.events.publish('banners:uploadBannerSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getBanners() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const bannersRef = this.afs.collection('features').doc('webbanners').collection('images');
            const bannersData = yield bannersRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return Object.assign({ id }, data);
            }))).pipe(first()).toPromise();
            this.events.publish('banners:publishBanners', bannersData);
        });
    }
    getSubscribedBanners() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const bannersRef = this.afs.collection('features').doc('banners').collection('images');
            const bannersData = yield bannersRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return Object.assign({ id }, data);
            })));
            bannersData.subscribe((banners) => {
                this.events.publish('banners:publishSubscribedBanners', banners);
            });
        });
    }
    getBannersActiveStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const activeStatus = yield this.afs.collection('features').doc('webbanners').valueChanges().pipe(first()).toPromise();
                this.events.publish('banners:publishBannersActiveStatus', activeStatus);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    changeBannersStatus(status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                //// console.log('status in service', status);
                yield this.afs.collection('features').doc('banners').set({
                    isActive: status
                });
                this.events.publish('banners:changeBannersStatusSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    removeBanner(index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('features').doc('banners').collection('images').doc(`image${index}`).delete();
                this.events.publish('banners:removeBannerSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
}
BannersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BannersService_Factory() { return new BannersService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage)); }, token: BannersService, providedIn: "root" });
