import { Component, OnInit } from '@angular/core';
import { DesignStudioService } from 'src/app/services/design-studio/design-studio.service';


@Component({
    selector: 'app-test-page',
    templateUrl: './test-page.page.html',
    styleUrls: ['./test-page.page.scss'],
})
export class TestPagePage implements OnInit {
    stickyHeight;
    stickyHeader;
    myThumbnail="https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg";
    myFullresImage="https://wittlock.github.io/ngx-image-zoom/assets/fullres.jpg";
    showZoom = false;
    itemDataOne = {
        title: "block title",
        style: "style-two",
        grids: {
            lg: "4",
            md: "3",
            sm: "1"
        },
        items: [
            {
                title: "Soft Drinks",
                link: "shop-subcategories/soft-drinks/vSmOSjM2PE0CoeykvvIA",
                image: {
                    url: "https://storage.googleapis.com/pt-app-4148f.appspot.com/categories/vSmOSjM2PE0CoeykvvIA/image/mob_B3d5ybypJclfw32SwZp5.png?GoogleAccessId=firebase-adminsdk-gpimi%40pt-app-4148f.iam.gserviceaccount.com&Expires=16730323200&Signature=I97hML0rymr7JDQui3I3%2BBMvr0m4566ZKBNhQZMpFTx08XQj8%2B%2FDUaAtRpFelvO%2BClIlJGeswXGWy%2F6S7iKYqOiayBp1jeOFY4KGNVbSQZyzxYepQYLapcge09GE812P8PVTeeg2reWZ4%2Fq1fIjNvAg9yWp6V7EVHpSgKSmViCOeuqn7mH%2BmMenxloD8aSjgBNTxyXR%2F8clv6DH3xGdjkG0ZCf6zVLsfCxqDCuZxpkHmc4wUNLO4Nqii%2B%2BdPr%2FKnBA0a9WVCIzJy9g8EB2mrdoojBThPhCEXU%2Fn8mFlXjf0l3dzNjXZTHEfH2Nv2rZFZGwzReGe80yW5K6A4B7%2BN6g%3D%3D"
                }
            },
            {
                title: "Testing Category",
                link: "shop-subcategories/testing-category/3tfxp2WQ7jHB7wSr3XC3",
                image: {
                    url: "https://storage.googleapis.com/pt-app-4148f.appspot.com/categories/3tfxp2WQ7jHB7wSr3XC3/image/mob_zCceVuFyTc0W8KUhean8.png?GoogleAccessId=firebase-adminsdk-gpimi%40pt-app-4148f.iam.gserviceaccount.com&Expires=16730323200&Signature=uxpL17sRp7ChkJt8%2B2Gl0OnmTSFnrVMPsieSBeIjxMqakH%2BLsC%2FL7fLklVkEtfSUtVw4TTLt7%2FQGoJMgz81NOpWmX6X8dB2Nu8PfiEcHP4yv8zakELwF5JuQNE6gwdc7jkxvUmSbN6XMSjf1TbIZvMbQg2UTAbQKil27MwVJxNRD0xp2oNhjmszItrLtW1b4jwWCRSTS%2F0VPR%2FZYsCn45KvXa5gsl8VJGijKIDFUJgfHHxg6FmFWkAdj67qSdqI252O5SDoRN5TpZQlaBXGEg7R418RxAY%2B6kH%2BMksvMYt9GWlB14AAbkDUVUQ2CdLZWHM3%2B9FZllwqbhsFgKwlTcQ%3D%3D"
                }
            },
            {
                title: "Rice & Rice Products",
                link: "shop-subcategories/rice-%2526-rice-products/5UD5K6cGM0mbFh2L9vyn",
                image: {
                    url: "https://storage.googleapis.com/pt-app-4148f.appspot.com/categories/5UD5K6cGM0mbFh2L9vyn/image/mob_1GZ6WMErXC9uRZDM5Jhz.png?GoogleAccessId=firebase-adminsdk-gpimi%40pt-app-4148f.iam.gserviceaccount.com&Expires=16730323200&Signature=GvmZRYLYSNgFANAAB1ZE0zndVYDHs8AFcmpo3eXRWTQJCPA86aKupEj2qH9%2Bdw%2BpxgWAzDgxgc1t%2Bc16X83dE291FL163RAC6QoR%2BIeUKUVjdQQx0wHsMrZ8d2pIwW8NhHyBCccCtTyeb6rL4wqhraWt7i16rqUTjME6WGr%2B5WqU0eTlhN%2FhY%2BxlTMvd70UjiyIzfE6uPb%2BapOFKfPLnPBD8ugfXG%2FA7f58v1Hroz7STysYdQVCCswnsl1c9su06Trs7x4xVrlKKqxP4fKtCYOI50w86d1cpMOPXHn7PrwKuCGv%2FRX3iaCNTHUDmchrrs77uqq1t4%2FBvHlvdz1iElw%3D%3D"
                }
            },
            {
                title: "Atta & Flour",
                link: "shop-subcategories/atta-%2526-flour/v8VLnM0rLyPznbnGqIy7",
                image: {
                    url: "https://firebasestorage.googleapis.com/v0/b/pt-app-4148f.appspot.com/o/categories%2Fv8VLnM0rLyPznbnGqIy7%2Fimage%2FwHE9w9jt7uQ17H8Z2mJH.png?alt=media&token=cd1f9f5b-8881-4717-b9c8-5a46a51b61b4"
                }
            },
            {
                title: "Dals & Pulse",
                link: "https://bwiwebv2.web.app/shop/category/dals-%2526-pulse/DdqoN5Aac2jk4gUROvZm",
                image: {
                    url: "https://storage.googleapis.com/pt-app-4148f.appspot.com/categories/DdqoN5Aac2jk4gUROvZm/image/mob_0a1DYxVqBt5GFWZ9GEl5.png?GoogleAccessId=firebase-adminsdk-gpimi%40pt-app-4148f.iam.gserviceaccount.com&Expires=16730323200&Signature=c3%2BRF8FDxbbZKa0UI1igjm0XleHb%2Fn7dKoX6FGgp4Sgtxj8oCiDl6Vnv4hlZ1ZqMB9tcbERCUogbj3MBd90O2waOBhwilEFd2jeCya9D4gHN%2BjySDX4QtdFgPtpWMMYI0H8OGtFqoVq5Xw%2Bn62HtI5HvtZE90D%2BYeXgWogyFV08Mb%2B9vtplIGvlXYcLvAKhwJQmHQ5s3G0b11FO9kooK1EGdjSjO1S67bLmOxNugT4cSD4OXCQCKIkvReboJ0CtgcOBH%2BdAt%2BwYdZP%2B25aLmsW8Juj%2FPMr3MIhCrv9l%2BS%2BR%2B2PvnATWh61xe7oW1I8ob8AtCqwYxZ6wWWwg7RFC9Ug%3D%3D"
                }
            },
            {
                title: "Dry Fruits",
                link: "shop/category/dry-fruits/Hu9qwGyFqoAv2fzSp9SC",
                image: {
                    url: "https://storage.googleapis.com/pt-app-4148f.appspot.com/categories/Hu9qwGyFqoAv2fzSp9SC/image/mob_KXB7COr3Jjt0r6iELDnG.png?GoogleAccessId=firebase-adminsdk-gpimi%40pt-app-4148f.iam.gserviceaccount.com&Expires=16730323200&Signature=fq2FzR3nPjTuLFdBxyosD9cTXLFOyZwHujxr9ZiGYWAFyP9XwmGyh7srtH67%2FQCuPfIs5xEXsdOQjqpMmgsD2OJc2I0%2Bcj2b3z8jnUYM483rZAUgwVLgUu7Fv2b%2FL%2FL4%2BwHRE5CfZk3tK3Wxics98y6TrBQe3qaHGgLbldAr8vUD3fLlwvZ069AAyi0JPRaiR19TVOg9Cyzs3ZDLATj%2B%2BqGR993ySifwnkHouAbckjkj5xnnB7ERdm33mctw7z3elPECIkBQMauOhOn8ssldul6W7bg%2BM%2FhKa%2FtwhTReWlgSDXNc%2FltIq51FUkNXQwNtAcf4OviCj32Uh5gjyzqZZA%3D%3D"
                }
            },
            {
                title: "Body Care Product",
                link: "shop/category/body-care-product/4vLCvVQn6y7VChEtGNj7",
                image: {
                    url: "https://storage.googleapis.com/pt-app-4148f.appspot.com/categories/4vLCvVQn6y7VChEtGNj7/image/mob_GvLcS3UIK2lLDpfpDZYg.png?GoogleAccessId=firebase-adminsdk-gpimi%40pt-app-4148f.iam.gserviceaccount.com&Expires=16730323200&Signature=Nx66bSyfWst53T%2FmOk4eFtx3aMf7m3%2F2axnWoNr3qZAB6KPGZJhvocc6Bs%2BlZOuVg1hmMUYHZ6iIYo4m0ypwof0KP6C7SEBwYnD70AJ5xXTjyJuOioHemUvlOrMeLiV7ipkP8ghiIE7hg7J7rcCT86JLRQ8142MduXzIqSgKTslMsKC1EHQzFG%2F86YQ43OMPln%2BPKDDwLEaNEEIv2MhoJmSlJ6mRuQ0BEL7kjg711Kk2fFoO5hPHD%2BFFO%2BHhJEDV34DGDRctabwyKxPtGmK%2BQFHvbGTRDsBb1KMyfHfvf1wjt0WiNkxNQoywNahg%2FQ%2B1Av1hr%2Fy4bPDyj4kZquRYtg%3D%3D"
                }
            },
            {
                title: "Home Decoration",
                link: "shop-subcategories/home-decoration/TrjynNexfzGpp67HQR7n",
                image: {
                    url: "https://storage.googleapis.com/pt-app-4148f.appspot.com/categories/TrjynNexfzGpp67HQR7n/image/mob_VucKScJCptoYr7ukQKXy.png?GoogleAccessId=firebase-adminsdk-gpimi%40pt-app-4148f.iam.gserviceaccount.com&Expires=16730323200&Signature=tGxDB1YKaWljBh6%2FKUdrpZQFu3KU%2BIxvPzlawFllrzO2HOUZuwMnlP6eEw5AJgXYveZP7guC2eeCxJ3Qt1QQYrjgFTM6hubSlnCVTkWdMYte2QsykLrnR528II3lAr0GffT4OCOCRFiEwElo2wKuCQHT2LwDUW4YkTumGKewqHlry6b8b1ZoiMC0O%2BghVI0zcGwU5yw1SeL6lfSlrfeIZLF0PMgIuO8lCFpMGp3SMf3Q%2FnuUYOnUpdqOw1Ht5Z%2BAbNf5CqJ0mT0ggGXPk%2FtN%2FimKm9zxlDGs2Lsg1tQR0gpvjDsq6omAYYdmuj%2BTBsxAWzCRtbbg9AHypCwKPV0Hgg%3D%3D"
                }
            },
            {
                title: "House Plant Set",
                link: "shop-subcategories/house-plant-set/iDkVux5U75QVRSypY8mi",
                image: {
                    url: "https://storage.googleapis.com/pt-app-4148f.appspot.com/categories/iDkVux5U75QVRSypY8mi/image/mob_wGEQqlo1wJHWNaFc0lAX.png?GoogleAccessId=firebase-adminsdk-gpimi%40pt-app-4148f.iam.gserviceaccount.com&Expires=16730323200&Signature=aV%2BlGaSbuDkQfZouxXBXEOQED1gpnY3KqmqAiBziUEtyGrQj0Qt4l6Ds%2F4ZK7Ct4RCUd7tmp6r4jIy64FTHNKfjRuEnNHJv%2FJYdWlMGO3RkqJEETjAGxO8K1Nao9LPsUH9pgSS8OyK8w2uEUGFUuEiH%2FNSGq00cX8RXUgGWAeeqlncLrakkJLdh8nN%2BavI6V5bDBsKngaPWlxG4JBG%2FkSRyOl2RPShrV7wgmHB4tNj%2F5lUGNdT6ksG79Vbd%2Fbdd8%2FMGTuwbQV%2BROC5fAbedEvzIUk4YKq%2F0MqtZ6Y3OrtQrNNI041NBTtC9Qo9OR4f90yln1acQG0OHazdXN8JiOKg%3D%3D"
                }
            },
            {
                title: "Curtain's",
                link: "shop/category/curtain's/UXOxNvMvosqGesccXixt",
                image: {
                    url: "https://storage.googleapis.com/pt-app-4148f.appspot.com/categories/UXOxNvMvosqGesccXixt/image/mob_Ck8fO904m8EYOCQeeVIv.png?GoogleAccessId=firebase-adminsdk-gpimi%40pt-app-4148f.iam.gserviceaccount.com&Expires=16730323200&Signature=NU2nqHhCch2PwPvyysxjPh0ww5jOMIDW4Cyr%2FCMNxaFLH0RZe0lnMCcyesnC5w8cIfV%2FEEEy5j81n8XlkrM3cKL5dyqYjBFwKe4O8HLe%2Bnck5nTyb686AWAw3WGCXKdi1fDfR5Xe%2BkLM1scAI1lRVA0dxA0kmPyEjjGm5IsAEtsmlynszF00jQFmsTRrN13uSJZW8xxG2L5aOevKzzf%2F4Ob5pdMmDajMe0OOWoJaiAWDn2JAZqh%2FggbobHFiQMWyaLg%2BuQHghphYWUR6Pak%2F15stNLSPFE%2FsFyiEjBwTbiO3nygRhgs1R46O27pKptHFo6RqbkGq4WwuyciEzQR1ow%3D%3D"
                }
            }
        ]
    }
    constructor(private designStudioService: DesignStudioService,) { }

    ngOnInit() {
        setTimeout(()=>{                           // <<<---using ()=> syntax
            this.showZoom = true;
            console.log('do zoom')
        }, 9000);
    }

    setCatgData() {
        this.designStudioService.setCatgData(this.itemDataOne)
    }

    getStickyHeight(height) {
        this.stickyHeight = height;
        console.log('sticky height', this.stickyHeight)
    }
    getScrollPos(pos: number) {
        if (pos > this.stickyHeight) {
            this.stickyHeader = true;
        } else {
            this.stickyHeader = false;
        }
    }

}
