import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { AngularFireStorage } from '@angular/fire/storage';
import { map, first } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AdminSettingsService {

  constructor(private afs: AngularFirestore, 
              private events: Events,
              private fbStorage: AngularFireStorage,
              private storage: Storage) { }
  initializeSubscriptions() {
//('admin service initializeSubscriptions')

    this.events.subscribe('admin-settings:saveSettingsData', (data, authSign, shopLogo) => {
      this.saveSettingsData(data, authSign, shopLogo);
    });
    this.events.subscribe('admin-settings:getSettingsData', () => {
      this.getSettingsData();
    });
    this.events.subscribe('admin-settings:getInvoiceData', () => {
      this.getInvoiceData();
    });
    this.events.subscribe('admin-settings:getStatesData', () => {
      this.getStatesData();
    });
    this.events.subscribe('admin-settings:savePaymentSettingsData', (data) => {
      this.savePaymentSettingsData(data);
    });
    this.events.subscribe('admin-settings:getPaytmData', () => {
      this.getPaytmData();
    });
    this.events.subscribe('admin-settings:getRazorPayData', () => {
      this.getRazorPayData();
    });
    this.events.subscribe('admin-settings:getPaymentInfoData', () => {
      this.getPaymentInfoData();
    });
    this.events.subscribe('admin-settings:saveTermsAndPrivacy', (data) => {
      this.saveTermsAndPrivacy(data);
    });
    this.events.subscribe('admin-settings:getTermsAndPrivacyData', () => {
      //// console.log('service subscribe admin-settings:getTermsAndPrivacyData')
      this.getTermsAndPrivacyData();
    });
    this.events.subscribe('admin-settings:getStoreInfo', () => {
      this.getStoreInfo();
    });
    this.events.subscribe('admin-settings:getPlanDetails', () => {
      this.getPlanDetails();
    });

    this.events.subscribe('admin-settings:getSubscribedStoreInfo', () => {
      this.getSubscribedStoreInfo();
    });

    this.events.subscribe('admin-settings:getWebsiteSEOData', () => {
      this.getSEOData();
    });

    this.getAppInfo() ;
    this.getStoreInfo;
  }

  async getSEOData() {
    // console.log('service getSEOData')
    try {
      const seoData: any = await this.afs.collection('settings').doc('seo').valueChanges().pipe(first()).toPromise();
      //// console.log(seoData);
      if(seoData){
        this.events.publish('admin-settings:publishSEOData', seoData);
      }
      
    } catch (error) {
      console.dir(error);
    }
  }

  async saveSettingsData(data, authSign, shopLogo) {
    try {
      const settingsRef = this.afs.collection('settings').doc('store');
      const invoiceRef = this.afs.collection('settings').doc('invoice');
      settingsRef.get().subscribe(async (doc) => {
        if(doc.exists) {
          await settingsRef.update(data);
        } else {
          await settingsRef.set(data);
        }
      });
      const invoice = {
        shopLogo: shopLogo,
        signature: authSign
      }
      invoiceRef.get().subscribe(async (doc) => {
        if(doc.exists) {
          await invoiceRef.update(invoice);
        } else {
          await invoiceRef.set(invoice);
        }
      });
      this.events.publish('admin-settings:saveSettingsDataSuccess');
    } catch (error) {
      console.dir(error);
    }
  }
  async getSettingsData() {
    return new Promise<any>(async (resolve) => {
      try {
        const settingsData: any = await this.afs.collection('settings').doc('store').valueChanges().pipe(first()).toPromise();
        console.log(settingsData);
        resolve(settingsData);
      } catch (error) {
        console.dir(error);
      }
    });
  }
  async getInvoiceData() {
    try {
      const invoiceData: any = await this.afs.collection('settings').doc('invoice').valueChanges().pipe(first()).toPromise();
      //// console.log(invoiceData);
      this.events.publish('admin-settings:publishInvoiceData', invoiceData);
    } catch (error) {
      console.dir(error);
    }
  }

  async getStatesData() {
    try {
      const statesData: any = await this.afs.collection('settings').doc('states').valueChanges().pipe(first()).toPromise();
      //// console.log(statesData);
      this.events.publish('admin-settings:publishStatesData', statesData.codes);
    } catch (error) {
      console.dir(error);
    }
  }
  async savePaymentSettingsData(data) {
    try {
      const paytmRef = this.afs.collection('payment').doc('paytm');
      const razorpayRef = this.afs.collection('payment').doc('razorpay');
      const infoRef = this.afs.collection('payment').doc('info');
      await paytmRef.set({
        active: data.paytmActive,
        merchantId: data.paytmMerchantId,
        key: data.paytmKey
      });
      await razorpayRef.set({
        active: data.razorpayActive,
        id: data.razorpayId,
        keySecret: data.razorpayKeySecret
      });
      await infoRef.set({
        autoConfirmOrder: data.autoConfirmOrder,
        gstNo: data.gstNo,
        defaultGst: data.defaultGst,
        minOrderAmount: data.minOrderAmount,
        maxOrderAmount: data.maxOrderAmount,
        isPackagingCharges: data.isPackagingCharges,
        packaging: {
          label: data.packaging.label,
          price: data.packaging.price
        },
        isGstApplicable: data.isGstApplicable,
        panNo: data.panNo,
        isCod: data.isCod
      });
      this.events.publish('admin-settings:savePaymentSettingsDataSuccess');
    } catch (error) {
      console.dir(error);
    }
  }
  async getPaytmData(callType?) {
    try {
      const paytmData: any = await this.afs.collection('payment').doc('paytm').valueChanges().pipe(first()).toPromise();
      if(callType && callType === 'return') {
        return paytmData;
      } else {
        this.events.publish('admin-settings:publishPaytmData', paytmData);
      }
    } catch (error) {
      console.dir(error);
    }
  }
  async getRazorPayData(callType?) {
    try {
      const razorpayData: any = await this.afs.collection('payment').doc('razorpay').valueChanges().pipe(first()).toPromise();
      if(callType && callType === 'return') {
        return razorpayData;
      } else {
        this.events.publish('admin-settings:publishRazorPayData', razorpayData);
      }
    } catch (error) {
      console.dir(error);
    }
  }
  async getPaymentInfoData(callType?) {
    try {
      const infoData: any = await this.afs.collection('payment').doc('info').valueChanges().pipe(first()).toPromise();
      if(callType && callType === 'return') {
        return infoData;
      } else {
        this.events.publish('admin-settings:publishPaymentInfoData', infoData);
      }
    } catch (error) {
      console.dir(error);
    }
  }
  async saveTermsAndPrivacy(data) {
    try {
      const settingsRef = this.afs.collection('settings').doc('policies');
      settingsRef.get().subscribe(async (doc) => {
        if(doc.exists) {
          await settingsRef.update({
            terms: data.terms,
            privacy: data.privacy
          });
        } else {
          await settingsRef.set({
            terms: data.terms,
            privacy: data.privacy
          });
        }
      });
      this.events.publish('admin-settings:saveTermsAndPrivacySuccess');
    } catch (error) {
      console.dir(error);
    }
  }
  async getTermsAndPrivacyData() {
    // console.log('getTermsAndPrivacyData');
    try {
      const policiesData: any = await this.afs.collection('settings').doc('policies').valueChanges().pipe(first()).toPromise();
      //// console.log('getTermsAndPrivacyData', policiesData);
      
      this.storage.set('policiesData', policiesData).then((result) => {
        //// console.log('policiesData set to storage')
        this.events.publish('admin-settings:publishTermsAndPrivacyData');
        });
    } catch (error) {
      console.dir(error);
    }
  }
  

  async getPlanDetails() {
    try {
      this.afs.collection('settings').doc('plan').valueChanges()
      .subscribe((res) => {
        this.events.publish('admin-settings:publishPlanDetails', res)
      });
    } catch (error) {
      console.dir(error);
    }
  }

  async getStoreInfo(callType?: string) {
    try {
      const storeInfo = await this.afs.collection('settings').doc('store').valueChanges().pipe(first()).toPromise();
      this.storage.set('storeInfo', storeInfo);
      if(callType === 'return') {
        return storeInfo;
      } else {
        this.events.publish('admin-settings:publishStoreInfo');
      }
    } catch (error) {
      console.dir(error);
    }
  }

  async getStoreInfoDataInSessionStorage() {
    return new Promise(async (resolve, reject) => {
        let data = JSON.parse(sessionStorage.getItem("storeInfoData")) || {};
        if (!(Object.keys(data).length)) {
          data = await this.afs.collection('settings').doc('store').valueChanges().pipe(first()).toPromise();
            sessionStorage.setItem("storeInfoData", JSON.stringify(data));
        }
        resolve(data);
    });
}

  async getSubscribedStoreInfo() {
    try {
      this.afs.collection('settings').doc('store')
      .valueChanges()
      .subscribe((response) => {
        this.storage.set('storeInfo', response);
        this.events.publish('admin-settings:storeInfo', response);
      });
    } catch (error) {
      console.dir(error);
    }
  }

  async getAppInfo() {
    try {
      this.afs.collection('settings').doc('app').valueChanges()
      .subscribe((res) => {
        this.storage.set('appInfo', res).then((result) => {
          this.events.publish('admin-settings:publishAppInfo');
        });
      });
    } catch (error) {
      console.dir(error);
    }
  }

  async getStripeData() {
    try {
      const stripeData: any = await this.afs.collection('payment').doc('stripe').valueChanges().pipe(first()).toPromise();
      return stripeData && Object.keys(stripeData).length ? stripeData : {active: false};
    } catch (error) {
      console.dir(error);
      return {active: false};
    }
  }

  async getPaymentGateway(gatewayChoice){
    try {
      const gatewayData: any = await this.afs.collection('payment').doc(gatewayChoice).valueChanges().pipe(first()).toPromise();
      return gatewayData && Object.keys(gatewayData).length ? gatewayData : {active: false};
    } catch (error) {
      console.dir(error);
    }
  }

  async getSettingsWithDocId(docId: string) {
    return new Promise<any>(async (resolve) => {
      try {
        const settingsData: any = await this.afs.collection('settings').doc(docId).valueChanges().pipe(first()).toPromise();
        resolve(settingsData || null);
      } catch (error) {
        console.dir(error);
        resolve(null);
      }
    });
  }
}
