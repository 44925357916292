import { Component, NgZone, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { ModalController } from '@ionic/angular';
declare const google;

@Component({
    selector: 'app-place-modal',
    templateUrl: './place-modal.page.html',
    styleUrls: ['./place-modal.page.scss'],
})
export class PlaceModalPage implements OnInit {

    SearchPlacesForm: NgForm;
    public shippingAddress: string;
    userPlace = {
        place: '',
        lat: null,
        lng: null
    }
    constructor(private vendorService: VendorService,
      private modalController: ModalController,
      private ngZone: NgZone) {
    }
    ngOnInit(): void {
        this.initAutocomplete();
    }

    initAutocomplete() {
        const input = document.getElementById("txtSearchPlaces") as HTMLInputElement;
        const autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.setFields([
            "address_components",
            "geometry",
            "icon",
            "name"
        ]);
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            console.log('place', place);
            if (!place.geometry) {
                alert('No details available for input:' + input.value);
                return;
            } else {
                this.ngZone.run(() => {
                  this.userPlace.place = place.name;
                  this.userPlace.lat = place.geometry.location.lat();
                  this.userPlace.lng = place.geometry.location.lng();
                });
            }
        });
    }

    disableApply() {
        return !this.userPlace.lat;
    }

    saveLocation() {
      this.vendorService.saveLocation(this.userPlace);
      this.modalController.dismiss();
    }

}
